import React, { Component } from 'react';

import classnames from 'classnames';
import { Sparkles } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import AJAX from 'common/AJAX';
import Colors from 'common/colors/constants';
import AutopilotSourceLogo from 'common/common/AutopilotSourceLogo';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import IsAdminViewContext from 'common/contexts/IsAdminViewContext';
import connect from 'common/core/connect';
import ExternalLink from 'common/ExternalLink';
import { hasTranslation } from 'common/i18n/translateString';
import TranslationToggle from 'common/i18n/TranslationToggle';
import AccessModal from 'common/modals/AccessModal';
import ErrorModal from 'common/modals/ErrorModal';
import MarkSpamModal from 'common/modals/MarkSpamModal';
import PostLink from 'common/post/PostLink';
import Heart from 'common/reaction/Heart';
import ReactionsMenu from 'common/reaction/ReactionsMenu';
import Spinner from 'common/Spinner';
import Strings from 'common/Strings';
import AdminFeedbackCommentComposer from 'common/subdomain/admin/AdminFeedbackCommentComposer';
import Tappable from 'common/Tappable';
import Timestamp from 'common/Timestamp';
import { Span } from 'common/ui/Text';
import devURL from 'common/util/devURL';
import getCannyOrigin from 'common/util/getCannyOrigin';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';
import { ConfigContext, IsIntercomContext } from 'common/widget/WidgetContext';

import CommentComposer from './CommentComposer';
import { reloadPost } from '../actions/posts';
import { reloadPostActivity } from '../actions/postsActivity';

import 'css/components/comment/_CommentMenu.scss';

class CommentMenu extends Component {
  static propTypes = {
    board: PropTypes.object,
    comment: PropTypes.shape({
      author: PropTypes.shape({
        _id: PropTypes.string,
        aliasID: PropTypes.string,
        serviceAccount: PropTypes.bool,
      }).isRequired,
      created: PropTypes.string,
    }).isRequired,
    company: PropTypes.shape({
      subdomain: PropTypes.string,
      viewerIsMember: PropTypes.bool,
    }),
    config: PropTypes.object,
    hideTranslation: PropTypes.bool,
    isAdminView: PropTypes.bool,
    isIntercom: PropTypes.bool,
    isWidget: PropTypes.bool,
    location: PropTypes.object,
    onEditComment: PropTypes.func,
    onMarkedSpam: PropTypes.func,
    onPinToggle: PropTypes.func,
    onToggleTranslation: PropTypes.func,
    openModal: PropTypes.func,
    pinned: PropTypes.bool,
    post: PropTypes.object,
    spam: PropTypes.bool,
    tintColor: PropTypes.string,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
      admin: PropTypes.bool,
    }),
  };

  state = {
    showReplyComposer: false,
    markingComment: false,
  };

  isViewerAuthor = () => {
    const { comment, viewer } = this.props;
    return comment.author && comment.author._id === viewer._id;
  };

  onConfirmedMarkedSpam = () => {
    const { comment, onMarkedSpam, openModal } = this.props;
    const { markingComment } = this.state;

    if (markingComment) {
      return;
    }

    this.setState({ markingComment: true });

    AJAX.post(
      '/api/spam/mark',
      {
        commentID: comment._id,
      },
      (response) => {
        this.setState({ markingComment: false });

        if (response !== 'success') {
          openModal(ErrorModal, {
            message: Strings.miscError,
          });
          return;
        }

        onMarkedSpam();
      }
    );
  };

  onEditClicked = () => {
    this.props.onEditComment();
  };

  onMarkNotSpamTapped = async () => {
    const { comment, onMarkedNotSpam, openModal } = this.props;
    const { markingComment } = this.state;

    if (markingComment) {
      return;
    }

    this.setState({ markingComment: true });

    const response = await AJAX.post('/api/spam/unmark', {
      objectID: comment._id,
      objectType: 'comment',
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({ markingComment: false });
      openModal(ErrorModal, {
        message: Strings.miscError,
      });
      return;
    }

    await onMarkedNotSpam();
    this.setState({ markingComment: false });
  };

  onMarkedSpamTapped = () => {
    const { company, openModal, viewer } = this.props;
    const viewerIsBae = !!viewer.cannyBae;
    const { markingComment } = this.state;

    if (markingComment) {
      return;
    }

    if (!viewerIsBae) {
      if (!hasPermission('deleteUsers', company, viewer)) {
        openModal(AccessModal, {
          requiredPermissions: ['deleteUsers'],
        });
        return;
      }
    }

    openModal(MarkSpamModal, {
      onConfirm: this.onConfirmedMarkedSpam,
    });
  };

  onPinTapped = async () => {
    const { comment, company, onPinToggle, openModal, post, viewer } = this.props;
    const viewerIsBae = !!viewer.cannyBae;

    if (!viewerIsBae) {
      if (!hasPermission('pinComments', company, viewer)) {
        openModal(AccessModal, {
          requiredPermissions: ['pinComments'],
        });
        return;
      }
    }

    const response = await AJAX.post('/api/comments/pin', {
      commentID: comment.pinned ? null : comment._id,
      postID: post._id,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      openModal(ErrorModal, { message: error.message });
      return;
    }

    onPinToggle();
  };

  onReplyTapped = () => {
    this.setState({
      showReplyComposer: !this.state.showReplyComposer,
    });
  };

  onReplyCreated = () => {
    this.setState({
      showReplyComposer: false,
    });
  };

  renderEditComment() {
    const {
      comment,
      company: { viewerIsMember },
      viewer,
    } = this.props;
    const viewerIsAuthor = this.isViewerAuthor();
    const viewerIsBae = viewer.cannyBae;

    // regular users can't edit their comments when pinned.
    if (!viewerIsMember && comment.pinned) {
      return null;
    }

    if (!viewerIsAuthor && comment.internal) {
      return null;
    }

    if (!viewerIsAuthor && !viewerIsMember && !viewerIsBae) {
      return null;
    }

    if (comment.spam && !viewerIsAuthor) {
      return null;
    }

    return (
      <div className="menuLink">
        <div className="middot">&middot;</div>
        <Tappable onTap={this.onEditClicked}>
          <div className="edit">Edit Comment</div>
        </Tappable>
      </div>
    );
  }

  renderInternal() {
    const { comment } = this.props;
    if (!comment.internal || comment.aiGenerated) {
      return null;
    }

    // when a comment is manually merged we want the middot to be after the internal label
    const manuallyMergedPostDraft = !!comment.integrationSourceType;

    return (
      <div className="menuLink">
        <div className="middot">&middot;</div>
        <div className="internalLabel">Internal</div>
        {manuallyMergedPostDraft ? <div className="middot">&middot;</div> : null}
      </div>
    );
  }

  renderLikeIcon({ fill, stroke }) {
    const strokeColor = stroke ?? Colors.gray90;
    return <Heart fill={fill} stroke={strokeColor} />;
  }

  renderReactions() {
    const { board, comment, invalidatePostData, isAdminView } = this.props;

    const showReactorNames = isAdminView || board.settings.showVoterNames;
    const viewerIsAuthor = this.isViewerAuthor();

    if (comment.spam && !viewerIsAuthor) {
      return null;
    }

    return (
      <ReactionsMenu
        className="reactionsMenuContainer"
        allowedReactions={{
          like: this.renderLikeIcon,
        }}
        object={comment}
        objectType="comment"
        reloadObjectData={invalidatePostData}
        showReactorNames={showReactorNames}
      />
    );
  }

  renderMarkNotSpam() {
    const {
      comment,
      company: { viewerIsMember },
    } = this.props;
    const { markingComment } = this.state;

    if (!viewerIsMember || !comment.spam) {
      return null;
    }

    return (
      <div className="menuLink">
        <div className="middot">&middot;</div>
        {markingComment && (
          <div className="markNotSpamSpinnerContainer">
            <Spinner />
          </div>
        )}
        <Tappable onTap={this.onMarkNotSpamTapped}>
          <p className="spam">Mark not&nbsp;spam</p>
        </Tappable>
      </div>
    );
  }

  renderMarkSpam() {
    const {
      comment,
      company: { viewerIsMember },
      isAdminView,
      viewer,
    } = this.props;
    if (!this.props.onMarkedSpam || !isAdminView) {
      return null;
    }

    if (comment.internal) {
      return null;
    }

    const viewerIsBae = !!viewer.cannyBae;
    if (!viewerIsMember && !viewerIsBae) {
      return null;
    }

    const spamText = comment.spam ? 'Ban and delete user' : 'Mark Spam';
    return (
      <div className="menuLink">
        <div className="middot">&middot;</div>
        <Tappable onTap={this.onMarkedSpamTapped}>
          <p className={classnames({ spam: true, destructive: comment.spam })}>{spamText}</p>
        </Tappable>
      </div>
    );
  }

  renderReply() {
    const {
      comment,
      company: { features },
      parent,
      pinned,
    } = this.props;
    const replyTo = parent || comment;
    const planSupports = features?.internalComments;
    const viewerIsAuthor = this.isViewerAuthor();

    if (pinned || (replyTo?.internal && !planSupports)) {
      return null;
    }

    // Render reply only for the author if comment is spam
    if (comment.spam && !viewerIsAuthor) {
      return null;
    }

    return (
      <div className="menuLink">
        <div className="middot">&middot;</div>
        <Tappable onTap={this.onReplyTapped}>
          <div className="reply">Reply</div>
        </Tappable>
      </div>
    );
  }

  renderPin() {
    const { comment, company, viewer } = this.props;
    const hasAccess = hasPermission('pinComments', company, viewer);

    if (!hasAccess || comment.internal || comment.spam) {
      return null;
    }

    const message = comment.pinned ? 'Unpin\xa0Comment' : 'Pin\xa0Comment';

    return (
      <div className="menuLink">
        <div className="middot">&middot;</div>
        <Tappable onTap={this.onPinTapped}>
          <div className="pin">{message}</div>
        </Tappable>
      </div>
    );
  }

  renderReplyComposer() {
    if (!this.state.showReplyComposer) {
      return null;
    }

    const { board, comment, isAdminView, parent, post } = this.props;
    const Composer = isAdminView ? AdminFeedbackCommentComposer : CommentComposer;
    return (
      <Composer
        autoFocus={true}
        board={board}
        onCommentCreated={this.onReplyCreated}
        post={post}
        replyTo={parent || comment}
      />
    );
  }

  renderTimestamp() {
    const { board, comment, company, isIntercom, post } = this.props;
    if (!comment.created) {
      return null;
    }

    if (isIntercom) {
      const postURL = devURL(getCannyOrigin(company) + '/' + board.urlName + '/p/' + post.urlName);
      return (
        <a className="timestamp" href={postURL} rel="noopener" target="_blank">
          <Timestamp timestamp={this.props.comment.created} />
        </a>
      );
    }

    const viewerIsAuthor = this.isViewerAuthor();

    return (
      <div className="menuLink">
        {(viewerIsAuthor || !comment.spam) && <div className="middot">&middot;</div>}
        <div className="menuTimestamp">
          <PostLink post={this.props.post}>
            <Timestamp timestamp={this.props.comment.created} />
          </PostLink>
        </div>
      </div>
    );
  }

  renderTranslationToggle() {
    const { comment } = this.props;
    const translationObject = this.props.comment;
    const translationStringKeys = ['value'];
    if (!hasTranslation(translationObject, translationStringKeys)) {
      return null;
    }

    return (
      <div className="menuLink">
        <div className="middot">&middot;</div>
        <TranslationToggle
          className="translationToggle"
          hideTranslation={this.props.hideTranslation}
          object={comment}
          onToggle={this.props.onToggleTranslation}
          stringKeys={['value']}
          variant="text"
        />
      </div>
    );
  }

  renderAutomated() {
    const {
      comment: { aiGenerated, sourceFeatureExtractionItemID },
    } = this.props;

    // only show if the comment is created by an auto-merge of an extracted feature
    if (!aiGenerated || !sourceFeatureExtractionItemID) {
      return null;
    }

    return (
      <div className="menuLink">
        <Sparkles className="automatedIcon" />
        <Span className="automated">Automated</Span>
        <div className="middot">&middot;</div>
      </div>
    );
  }

  renderSource() {
    const {
      comment: { integrationSourceType, sourceLink, itemSourceType },
    } = this.props;

    if (!integrationSourceType) {
      return null;
    }

    if (integrationSourceType === 'api') {
      const linkText = itemSourceType
        ? `${itemSourceType.slice(0, 20)}${itemSourceType.length > 20 ? '...' : ''}`
        : 'API';

      return (
        <div className="menuLink">
          <AutopilotSourceLogo className="sourceIcon" integrationName="api" size="small" />
          {sourceLink ? (
            <ExternalLink to={sourceLink}>
              <Span className="source">{linkText}</Span>
            </ExternalLink>
          ) : (
            <Span className="sourceType">{linkText}</Span>
          )}
        </div>
      );
    }

    if (!sourceLink) {
      return null;
    }

    return (
      <div className="menuLink">
        <AutopilotSourceLogo
          className="sourceIcon"
          integrationName={integrationSourceType}
          size="small"
        />
        <ExternalLink to={sourceLink}>
          <Span className="source">View Source</Span>
        </ExternalLink>
      </div>
    );
  }

  render() {
    return (
      <div className="commentMenu">
        <div className="menu">
          {this.renderAutomated()}
          {this.renderReactions()}
          {this.renderInternal()}
          {this.renderSource()}
          {this.renderTimestamp()}
          {this.renderTranslationToggle()}
          {this.renderEditComment()}
          {this.renderMarkNotSpam()}
          {this.renderMarkSpam()}
          {this.renderPin()}
          {this.renderReply()}
        </div>
        {this.renderReplyComposer()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch, ownProps) => ({
    invalidatePostData: () => {
      return Promise.all([
        dispatch(reloadPost(ownProps.post)),
        dispatch(reloadPostActivity(ownProps.post)),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      config: ConfigContext,
      isAdminView: IsAdminViewContext,
      isIntercom: IsIntercomContext,
      isWidget: IsWidgetContext,
      location: LocationContext,
      openModal: OpenModalContext,
      tintColor: TintColorContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(CommentMenu);
