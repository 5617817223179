import AJAX from 'common/AJAX';
import publicConfig from 'common/core/publicConfig';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import queryString from 'common/util/queryString';

import type {
  ReviewPlatformNames,
  SyncIntegrationNames,
} from 'common/constants/autopilotIntegrations';
import type { CountryCodes } from 'common/constants/countries';

type ConversationSource = {
  code: string;
  subdomain?: string;
};

type ReviewSource = {
  platform: ReviewPlatformNames;
  profileName: string;
  url: string;
  countryCode?: CountryCodes;
};

type InstallAndEnableParams = {
  integrationName: SyncIntegrationNames;
  integrationLabel: string;
} & (
  | {
      conversationSource: ConversationSource;
      reviewSource?: never;
    }
  | {
      conversationSource?: never;
      reviewSource: ReviewSource;
    }
);

export const installAndEnable = async ({
  integrationName,
  integrationLabel,
  conversationSource,
  reviewSource,
}: InstallAndEnableParams) => {
  const response = await AJAX.post('/api/queue/installAndEnable', {
    integrationName,
    conversationSource,
    reviewSource,
  });
  const { error } = parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
    errors: {
      'not authorized': `You don't have the permissions to install ${integrationLabel} in this company. Please contact an administrator.`,
      'no token':
        'Something went wrong with the installation. Please wait a few minutes and try reinstalling',
      'no account':
        'Something went wrong with the installation. Please wait a few minutes and try reinstalling',
      'installed in another company': `This ${integrationLabel} instance is aleady installed in a different company. Please uninstall it before installing it again.`,
    },
  });

  if (error) {
    throw error;
  }
};

/* INTERCOM */
const IntercomClientID = publicConfig('intercomClientID') as string;
const IntercomBaseURL = 'https://app.intercom.io';

export const getIntercomOAuthURL = (subdomain: string) => {
  const path = '/oauth';
  const query = queryString.stringify({
    client_id: IntercomClientID,
    redirect_uri: devURL('https://canny.io/intercom'),
    state: JSON.stringify({
      isGettingStarted: true,
      subdomain,
    }),
  });
  return IntercomBaseURL + path + query;
};

/* ZENDESK */
const ZendeskClientID = publicConfig('zendeskClientID') as string;
const ZendeskOAuthPath = '/oauth/authorizations/new';

export const getZendeskOAuthURL = (zendeskSubdomain: string, cannySubdomain: string) => {
  if (zendeskSubdomain === '') {
    return '#';
  }

  const query = queryString.stringify({
    client_id: ZendeskClientID,
    redirect_uri: devURL('https://canny.io/zendesk'),
    response_type: 'code',
    scope: 'apps:write read',
    state: JSON.stringify({
      subdomain: cannySubdomain,
      zendeskSubdomain,
      isGettingStarted: true,
    }),
  });
  return 'https://' + zendeskSubdomain + '.zendesk.com' + ZendeskOAuthPath + query;
};

/* HELPSCOUT */
const HelpscoutClientID = publicConfig('helpscoutClientID') as string;
const HelpscoutURL = 'https://secure.helpscout.net/authentication/authorizeClientApplication';

export const getHelpscoutOAuthUrl = (subdomain: string) => {
  const helpscoutParams = {
    client_id: HelpscoutClientID,
    state: JSON.stringify({
      integration: 'helpscout',
      subdomain,
      isGettingStarted: true,
    }),
  };
  return `${HelpscoutURL}${queryString.stringify(helpscoutParams)}`;
};
