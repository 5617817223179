import React, { useCallback, useContext, useState } from 'react';

import classnames from 'classnames';
import { type Dispatch } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import ModernModal from 'common/modals/ModernModal';
import ReviewIntegrationSearch, {
  RequestErrors,
  type SearchParams,
  type SearchResult,
} from 'common/subdomain/admin/AdminAutopilotSettings/ReviewIntegrationSearch/ReviewIntegrationSearch';
import { useGettingStartedContext } from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotGettingStarted';
import AdminAutopilotInfoBox from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotInfoBox';
import {
  InfoPoints,
  type Integration,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import { installAndEnable } from 'common/subdomain/admin/AdminQueue/GettingStarted/utils';
import ButtonV2 from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';
import parseAPIResponse from 'common/util/parseAPIResponse';
import useDelayer from 'common/util/useDelayer';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotReviewIntegrationModal.module.scss';

import type { Company, SyncReviewIntegration } from 'common/api/endpoints/companies';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import type { CountryCodes } from 'common/constants/countries';

type ConnectProps = {
  reloadCompany: () => void;
};

type OwnProps = {
  onClose: () => void;
  integration: Integration;
};

type Props = ConnectProps & OwnProps;

const defaultSearchParams = (installation?: SyncReviewIntegration) =>
  installation
    ? {
        search: installation.profileName,
        countryCode: installation.countryCode as CountryCodes,
      }
    : { search: '', countryCode: null };

const AdminAutopilotReviewIntegrationModal = ({ onClose, integration, reloadCompany }: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const { loading, setLoading, setErroredIntegrationName } = useGettingStartedContext();

  const installedSyncReviewIntegration = company.installedSyncReviewIntegrations.find(
    (installedIntegration) => installedIntegration.platform === integration.platform
  );

  const infoPoints = InfoPoints[integration.name] ?? InfoPoints.reviewDefault;

  // state
  const [selectedSource, setSelectedSource] = useState<SearchResult | null>(null);
  const [searchParams, setSearchParams] = useState<SearchParams>(
    defaultSearchParams(installedSyncReviewIntegration)
  );
  const [searchSuggestions, setSearchSuggestions] = useState<SearchResult[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);

  // helpers
  const searchForReviewSource = useCallback(
    async (params: SearchParams) => {
      const { search, countryCode } = params;
      if (!search || (integration.needsCountry && !countryCode)) {
        return;
      }

      if (selectedSource && selectedSource.name === search) {
        return;
      }

      setSearchSuggestions([]);
      setSearchLoading(true);

      const response = await AJAX.post(`/api/reviewSources/search`, {
        ...(countryCode && { countryCode }),
        platform: integration.platform,
        search: search.toLowerCase(),
      });

      const { error, parsedResponse } = parseAPIResponse(response, {
        isSuccessful: (response: { results: SearchResult[] }) => !!response.results,
        errors: RequestErrors,
      });

      if (error || !parsedResponse) {
        const message = error ? error.message : RequestErrors.default;
        showToast(message, ToastTypes.error);
        return;
      }

      const results = parsedResponse.results.length
        ? parsedResponse.results.map((result) => ({ ...result, canSelect: true }))
        : [];

      setSearchLoading(false);
      setSearchSuggestions(results);
    },
    [integration, showToast, selectedSource]
  );

  const searchDelayer = useDelayer(searchForReviewSource, 300);

  const installSource = async () => {
    if (!selectedSource || !searchParams) {
      return;
    }

    if (!integration.platform || !selectedSource.link) {
      return;
    }

    setErroredIntegrationName();
    setLoading(true);

    try {
      await installAndEnable({
        integrationName: integration.name,
        integrationLabel: integration.label,
        reviewSource: {
          ...(searchParams.countryCode && { countryCode: searchParams.countryCode }),
          platform: integration.platform,
          profileName: selectedSource.name,
          url: selectedSource.link,
        },
      });
    } catch (e) {
      return setErroredIntegrationName(integration.label);
    } finally {
      await reloadCompany();
      setLoading(false);
      onClose();
    }
  };

  const onSearchUpdate = (searchParams: SearchParams) => {
    searchDelayer(searchParams);
    setSearchParams(searchParams);
  };

  const onSourceSelected = (source: SearchResult) => {
    setSelectedSource(source);
    setSearchParams((prev) => ({ ...prev, search: source.name }));
  };

  // render
  const getConnectButton = () => {
    const installedSyncIntegration = company.installedSyncIntegrations.find(
      ({ integrationName }: { integrationName: SyncIntegrationNames }) =>
        integrationName === integration.name
    );

    // Source hasn't been installed
    // or Source is installed, but the sync is disabled (we should replace the selected source + country code)
    if (
      !installedSyncIntegration ||
      (installedSyncIntegration && installedSyncIntegration.disabled)
    ) {
      return (
        <ButtonV2
          loading={loading}
          size="medium"
          onClick={installSource}
          disabled={!selectedSource}>
          Connect
        </ButtonV2>
      );
    }

    // integration is installed and enabled - base button should be disabled
    return null;
  };

  const header = <span className={styles.header}>Connect {integration.label} to Autopilot</span>;
  const footer = (
    <>
      <ButtonV2 variant="outlined" size="medium" onClick={onClose}>
        Cancel
      </ButtonV2>
      {getConnectButton()}
    </>
  );

  return (
    <ModernModal
      header={header}
      onClose={onClose}
      sections={[
        <>
          <P className={styles.subheading}>
            Enable Autopilot to capture user feedback found in {integration.label} reviews.
          </P>
          <ReviewIntegrationSearch
            {...integration}
            stackedFields
            searchParams={searchParams}
            setSearchParams={onSearchUpdate}
            searchLabel="Select your product"
            searchSublabel={`Enter your product's name as listed on ${integration.label}`}
            onSourceSelected={onSourceSelected}
            className={classnames([
              styles.search,
              { [styles.needsCountry]: integration.needsCountry },
            ])}
            searchSuggestions={searchSuggestions}
            loading={loading}
            searchLoading={searchLoading}
          />
          <AdminAutopilotInfoBox points={infoPoints} />
        </>,
      ]}
      footer={footer}></ModernModal>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    return dispatch(reloadCompany());
  },
}))(AdminAutopilotReviewIntegrationModal) as unknown as React.FC<OwnProps>;
