import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import InformationBox from 'common/common/InformationBox';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminIntercomSettings.scss';

const IntercomBaseURL = 'https://app.intercom.io';
const IntercomClientID = publicConfig('intercomClientID');
const IntercomAppStorePath = '/appstore/redirect';

class AdminIntercomSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      intercom: PropTypes.object,
      logoURL: PropTypes.string,
      name: PropTypes.string,
      subdomain: PropTypes.string,
      tintColor: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        code: PropTypes.string,
        connect: PropTypes.string,
        redirect: PropTypes.string,
      }),
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
  };

  state = {
    code: this.props.location.query.code,
    error: null,
    needsReinstall: this.props.company.intercom?.lostAccess,
    redirect: this.props.location.query.redirect,
  };

  componentDidMount() {
    const { company, location, router } = this.props;

    const { intercom } = company;
    if (location.query.connect === 'true') {
      if (intercom) {
        // already connected, return to Intercom with an error
        const query = queryString.stringify({
          error_message: 'This Canny account is already connected to an Intercom account.',
        });
        const intercomURL = IntercomBaseURL + IntercomAppStorePath + query;
        window.location.replace(intercomURL);
        return;
      }

      // not connected, connect, then redirect to app store
      const intercomURL = this.getOAuthURL();
      window.location.assign(intercomURL);
      return;
    }

    const { code } = this.state;
    if (!code) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    AJAX.post(
      '/api/intercom/register',
      {
        code,
      },
      (response) => {
        const { redirect } = this.state;
        const { error } = parseAPIResponse(response, {
          isSuccessful: isDefaultSuccessResponse,
          errors: {
            'not authorized':
              "You don't have the permissions to install Intercom in this company. Please, contact an administrator.",
            'no token':
              'Something went wrong with the installation. Please wait a few minutes and try reinstalling',
            'no account':
              'Something went wrong with the installation. Please wait a few minutes and try reinstalling',
            'installed in another company':
              'This intercom instance is aleady installed in a different company. Please, uninstall it before installing it again.',
          },
        });

        if (error) {
          if (redirect) {
            const query = queryString.stringify({ error_message: error.message });
            const intercomURL = IntercomBaseURL + IntercomAppStorePath + query;
            window.location.replace(intercomURL);
            return;
          }

          this.setState({ error: error.message });
          return;
        }

        if (redirect) {
          const query = queryString.stringify({ install_success: 'true' });
          const intercomURL = IntercomBaseURL + IntercomAppStorePath + query;
          window.location.replace(intercomURL);
          return;
        }

        const { reloadCompany } = this.props;
        reloadCompany();
      }
    );
  }

  onDisconnect = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to disconnect your Intercom account?",
      onConfirm: () => {
        AJAX.post('/api/intercom/disconnect', {}, (response) => {
          if (response !== 'success') {
            this.setState({
              error: 'Something went wrong, please contact our team for support.',
            });
          } else {
            const { reloadCompany } = this.props;
            reloadCompany();
          }
        });
      },
    });
  };

  getOAuthURL = () => {
    const {
      company: { subdomain },
      location,
    } = this.props;
    const path = '/oauth';
    const query = queryString.stringify({
      client_id: IntercomClientID,
      redirect_uri: devURL('https://canny.io/intercom'),
      state: JSON.stringify({
        ...(location.query.connect && {
          redirect: true,
        }),
        subdomain,
      }),
    });
    return IntercomBaseURL + path + query;
  };

  renderAddButton() {
    const intercomURL = this.getOAuthURL();
    return (
      <a href={intercomURL} className="addToIntercomButton">
        <LazyLoadedImage
          alt="Add to Intercom"
          src="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png"
          srcSet="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png 1x, https://static.intercomassets.com/assets/oauth/primary@2x-0d69ca2141dfdfa0535634610be80994.png 2x, https://static.intercomassets.com/assets/oauth/primary@3x-788ed3c44d63a6aec3927285e920f542.png 3x"
        />
      </a>
    );
  }

  renderConnected() {
    const {
      company: { intercom },
    } = this.props;
    const { needsReinstall } = this.state;
    if (!intercom || needsReinstall) {
      return null;
    }

    return (
      <div className="connected">
        <div className="text">Your Intercom account is connected.</div>
        <div className="text">Account name: {intercom.name}</div>
        <Tappable onTap={this.onDisconnect}>
          <div className="disconnect">Disconnect</div>
        </Tappable>
      </div>
    );
  }

  renderDisconnected() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
      company: { intercom },
    } = this.props;
    if (intercom) {
      return null;
    }

    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    return this.renderAddButton();
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderReinstall() {
    const {
      company: { intercom },
    } = this.props;
    const { needsReinstall } = this.state;
    if (!intercom || !needsReinstall) {
      return null;
    }

    return (
      <>
        <InformationBox icon="!" className="withMarginBottom">
          Canny has lost access to your Intercom account. Please reinstall the integration to
          continue using it. Alternatively, disconnect the integration completely.
        </InformationBox>
        {this.renderAddButton()}
        <Tappable onTap={this.onDisconnect}>
          <div className="disconnect reinstall">Disconnect</div>
        </Tappable>
      </>
    );
  }

  render() {
    return (
      <div className="adminIntercomSettings">
        <Helmet title="Intercom Integration | Canny" />
        <div className="settingsHeading">Intercom Integration</div>
        <div className="content">
          <div className="description">
            <div className="text">
              Track feedback people give you in conversations. You can also let your users suggest
              and vote on features directly from Messenger&nbsp;home.
            </div>
            <div className="link">
              <a href="https://canny.io/integrations/intercom" rel="noopener" target="_blank">
                Learn more
              </a>
            </div>
            <div className="link">
              <a
                href="https://help.canny.io/en/articles/2048046-intercom-integration"
                rel="noopener"
                target="_blank">
                Setting up the integration
              </a>
            </div>
          </div>
          <div className="connectionStatus">
            {this.renderDisconnected()}
            {this.renderConnected()}
            {this.renderReinstall()}
          </div>
          {this.renderError()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.integrations.intercom),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminIntercomSettings);
