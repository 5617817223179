import React, { useContext } from 'react';

import { Confidence } from 'common/api/resources/inbox';
import Popover from 'common/common/Popover';
import { BoardsContext } from 'common/containers/BoardsContainer';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import { H3, P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_AdminQueueSettingsModal.module.scss';

import useFilterControls from '../useFilterControls';

import type { PostType } from 'common/actions/queueItemQueries';
import type { Board } from 'common/api/endpoints/boards';

type Props = {
  queryState: Record<string, string> | null;
  reloadCompany: () => void;
};

const ViewOptions: { label: string; type: PostType }[] = [
  { label: 'New posts', type: 'newPost' },
  { label: 'Duplicate posts', type: 'duplicate' },
];

const SpamConfidenceOptions = [
  { label: 'High confidence', value: Confidence.highConfidence },
  { label: 'Low confidence', value: Confidence.lowConfidence },
] as const;

const AdminQueueFiltersModal = ({ queryState, reloadCompany }: Props) => {
  const boards = useContext(BoardsContext);

  const { queryParams, updateFilters } = useFilterControls({ queryState, reloadCompany });

  if ('loading' in boards) {
    return null;
  }

  const onBoardSelect = (board: Board) => (checked: boolean) => {
    const currentValue = queryParams.boardURLNames ?? [];
    if (checked) {
      updateFilters({ boardURLNames: [...currentValue, board.urlName] });
    } else {
      const filteredBoards = currentValue.filter((urlName) => urlName !== board.urlName);

      updateFilters({ boardURLNames: filteredBoards.length ? filteredBoards : undefined });
    }
  };

  const onTogglePostType = (type: PostType) => (checked: boolean) => {
    const currentValue = queryParams.postTypes ?? [];
    if (checked) {
      updateFilters({ postTypes: [...currentValue, type] });
    } else {
      const filteredTypes = currentValue.filter((currentType) => currentType !== type);

      updateFilters({ postTypes: filteredTypes.length ? filteredTypes : undefined });
    }
  };

  const onToggleSpamConfidence = (value: Confidence) => (checked: boolean) => {
    const currentValue = queryParams.confidence;

    if (checked) {
      updateFilters({ confidence: [...currentValue, value] });
    } else {
      const filteredConfidence = currentValue.filter((confidenceItem) => confidenceItem !== value);
      updateFilters({ confidence: filteredConfidence });
    }
  };

  return (
    <Popover className={styles.settingsModal}>
      <div className={styles.content}>
        <H3 className={styles.subheading}>Boards</H3>
        {boards.length === 0 && <P>No boards found</P>}
        <ul className={styles.list}>
          {boards.map((board) => (
            <li key={board._id}>
              <CheckboxWithLabel
                aria-label={`toggle ${board.name}`}
                checked={queryParams.boardURLNames?.includes(board.urlName) ?? false}
                onChange={onBoardSelect(board)}
                size="medium">
                {board.name}
              </CheckboxWithLabel>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>
        <H3 className={styles.subheading}>View Options</H3>
        <ul className={styles.list}>
          {ViewOptions.map((option) => (
            <li key={option.type}>
              <CheckboxWithLabel
                checked={queryParams.postTypes?.includes(option.type) ?? false}
                onChange={onTogglePostType(option.type)}
                size="medium">
                {option.label}
              </CheckboxWithLabel>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>
        <H3 className={styles.subheading}>Spam</H3>
        <ul className={styles.list}>
          {SpamConfidenceOptions.map(({ label, value }) => (
            <li key={value}>
              <CheckboxWithLabel
                checked={queryParams.confidence.includes(value)}
                onChange={onToggleSpamConfidence(value)}
                size="medium">
                {label}
              </CheckboxWithLabel>
            </li>
          ))}
        </ul>
      </div>
    </Popover>
  );
};

export default AdminQueueFiltersModal;
