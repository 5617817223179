import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadAllBoards } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import TextToggle from 'common/common/TextToggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import ChangeInvoiceEmailModal from 'common/modals/ChangeInvoiceEmailModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import InvoicesModal from 'common/modals/InvoicesModal';
import UpsellModal from 'common/modals/UpsellModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminPlanErrors from 'common/subdomain/admin/AdminPlanErrors';
import AdminBillingPlan from 'common/subdomain/admin/billing/AdminBillingPlan';
import AdminCCModal from 'common/subdomain/admin/billing/AdminCCModal';
import Tappable from 'common/Tappable';
import Timestamp from 'common/Timestamp';
import getPlanNameForTrial from 'common/util/getPlanNameForTrial';
import isFree, { FreePlanID, FreeV1PlanID, FreeV2PlanID } from 'common/util/isFree';
import isGrowth, { GrowthAnnualPlanID, GrowthPlanID } from 'common/util/isGrowth';
import isStarter, { StarterAnnualPlanID, StarterPlanID } from 'common/util/isStarter';
import numberWithCommas from 'common/util/numberWithCommas';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/billing/_AdminBillingSettings.scss';

const FreeTrialID = '101';
const FreeTierID = '102';
const BizTrialPlanID = 'business-trial-1';

const changePlanErrors = {
  DoesNotQualify: 'does not qualify',
  DowngradeNotAllowed: 'downgrade not allowed',
  InvalidPlanID: 'invalid planID',
};

const Modals = {
  charge: 'charge',
  creditCard: 'creditCard',
  invoices: 'invoices',
  upsell: 'upsell',
};

const PlanNames = {
  business: 'Business',
  growth: 'Growth',
  starter: 'Starter',
};

const ExcludedDiscounts = {
  [PlanNames.starter]: [
    'YRoKegye', // startupV1 year one 75%
    'esUanZHm', // startupV1 year two 50%
    'h9ltb0jf', // startupV1 one year 50%
    'TfLHBqXA', // startupV2 year one 75%
    '6dBkiwBv', // startupV2 year two 50%
    'P142VUck', // startupV2 one year 50%
  ],
  [PlanNames.growth]: [
    'YRoKegye', // startupV1 year one 75%
    'esUanZHm', // startupV1 year two 50%
    'h9ltb0jf', // startupV1 one year 50%
  ],
};

const ReferralDiscounts = {
  monthly: {
    allowedPlanIDs: null,
    amountOff: null,
    couponID: 'monthly-referral-coupon',
    duration: 'repeating',
    durationInMonths: 3,
    percentOff: 20,
  },
  yearly: {
    allowedPlanIDs: null,
    amountOff: null,
    couponID: 'annual-referral-coupon',
    duration: 'once',
    durationInMonths: 12,
    percentOff: 5,
  },
};

class AdminBillingSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      billingData: PropTypes.shape({
        activeUntil: PropTypes.string,
        cancelled: PropTypes.bool,
        hasCard: PropTypes.bool,
        invoiceEmail: PropTypes.string,
        plan: PropTypes.object,
      }),
      contributorCount: PropTypes.number,
      customPlan: PropTypes.object,
    }),
    discordSettings: PropTypes.object,
    openModal: PropTypes.func,
    showIntercom: PropTypes.func,
    trackEvent: PropTypes.func,
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }),
  };

  state = {
    billingCycle: 'yearly',
    cardError: null,
    ccModalCallback: null,
    changingPlan: false,
    changingPlanID: null,
    changePlanErrors: null,
    changePlanSubmissionError: null,
    endingPlanTrial: false,
    endPlanTrialErrors: null,
    endPlanSubmissionError: false,
    error: false,
    lostFeatures: null,
    modal: null,
    planTrialLostFeatures: null,
    trialPlanID: null,
  };

  componentDidMount() {
    const { trackEvent } = this.props;
    trackEvent('Viewed Billing Page');
  }

  onCancelPlan = () => {
    // for now
    this.props.showIntercom();
  };

  onContactSupport = () => {
    this.props.showIntercom();
  };

  onChangeInvoiceEmail = () => {
    this.props.openModal(ChangeInvoiceEmailModal);
  };

  onChangePlan = async ({ confirm, planID }) => {
    this.setState({
      changePlanSubmissionError: null,
      changingPlan: true,
      changingPlanID: planID,
      error: false,
      cardError: null,
    });

    const response = await AJAX.post('/api/billing/changePlan', { confirm, planID });
    await this.props.reloadCompany();

    if (response === 'success') {
      this.setState({
        changingPlan: false,
        changingPlanID: null,
        changePlanErrors: null,
        lostFeatures: null,
      });
      return;
    }

    let responseJSON;
    try {
      responseJSON = JSON.parse(response);
    } catch (e) {
      responseJSON = null;
    }

    if (responseJSON?.error === 'no card') {
      const success = await this.onUpdateCard();

      if (success) {
        this.onChangePlan({ confirm, planID });
      }
      this.setState({ changingPlan: false });
      return;
    }

    this.setState({ changingPlan: false });
    if (responseJSON?.error === 'invalid planID') {
      if (confirm) {
        this.setState({ changePlanSubmissionError: changePlanErrors.InvalidPlanID });
      }
      return;
    }

    if (responseJSON?.error === 'downgrade not allowed') {
      this.props.openModal(ConfirmModal, {
        message:
          "We'd love to know why your current plan isn't working for you. Please contact support to downgrade.",
        onConfirm: this.onContactSupport,
        submitButtonValue: 'Contact Support',
      });
      return;
    }

    if (responseJSON?.error === 'does not qualify') {
      this.setState({
        changePlanErrors: responseJSON.errors,
        lostFeatures: responseJSON.lostFeatures?.length > 0 ? responseJSON.lostFeatures : null,
      });
      if (confirm) {
        this.setState({ changePlanSubmissionError: changePlanErrors.DoesNotQualify });
      }
      return;
    }

    if (responseJSON?.error === 'lost features unconfirmed') {
      this.setState({
        changePlanErrors: null,
        lostFeatures: responseJSON.lostFeatures,
      });
      return;
    }

    if (responseJSON?.error === 'card error') {
      this.setState({
        cardError: responseJSON.errorMessage,
      });
      return;
    }
    this.setState({
      error: true,
    });
  };

  onEndTrial = async ({ confirm }) => {
    this.setState({
      endingPlanTrial: true,
      endPlanTrialErrors: null,
      planTrialLostFeatures: null,
      endPlanSubmissionError: false,
    });

    const response = await AJAX.post('/api/billing/endTrial', { confirm });

    if (response === 'success') {
      this.props.reloadCompany();
      this.setState({
        endingPlanTrial: false,
        planTrialLostFeatures: null,
      });
      return;
    }

    let responseJSON;
    try {
      responseJSON = JSON.parse(response);
    } catch (e) {
      responseJSON = null;
    }

    if (responseJSON?.error === 'does not qualify') {
      this.setState({
        endingPlanTrial: false,
        endPlanTrialErrors: responseJSON.errors,
        planTrialLostFeatures:
          responseJSON.lostFeatures?.length > 0 ? responseJSON.lostFeatures : null,
      });
      return;
    }

    if (responseJSON?.error === 'lost features unconfirmed') {
      this.setState({
        endingPlanTrial: false,
        endPlanTrialErrors: null,
        planTrialLostFeatures: responseJSON.lostFeatures,
      });
      return;
    }

    this.setState({ endPlanSubmissionError: true, endingPlanTrial: false });
  };

  onShowInvoices = () => {
    this.setState({ modal: Modals.invoices });
  };

  onToggleBillingCycle = (billingCycle) => {
    this.setState({ billingCycle });
  };

  onCloseInvoices = () => {
    this.setState({ modal: null });
  };

  onCloseCCModal = async (error) => {
    const { ccModalCallback } = this.state;

    if (error) {
      this.setState({ cardError: error.message });
    }

    this.setState({
      ccModalCallback: null,
      modal: null,
    });
    ccModalCallback(false);
  };

  onSuccessCCModal = async () => {
    const { ccModalCallback } = this.state;

    this.setState({
      ccModalCallback: null,
      modal: null,
    });
    ccModalCallback(true);
  };

  onCloseChargeModal = async () => {
    const {
      company,
      viewer: { email },
    } = this.props;

    this.setState({
      modal: null,
      changingPlanID: null,
    });

    AJAX.post('/api/analytics/log', {
      key: 'annual_pricing_confirm_cancel',
      data: {
        company: company._id,
        viewer: email,
      },
    });
  };

  onStartTrial = (trialPlanID) => {
    this.setState({
      modal: Modals.upsell,
      trialPlanID,
    });
  };

  updateCard = async () => {
    this.setState({ modal: Modals.creditCard });

    return new Promise((resolve) => {
      this.setState({ ccModalCallback: resolve });
    });
  };

  onUpdateCard = async () => {
    this.setState({ cardError: null });

    const { trackEvent } = this.props;
    trackEvent('Clicked Change Card (Billing)');

    const success = await this.updateCard();

    if (!success) {
      trackEvent('Failed To Add Card (Billing)');
      return false;
    }

    await this.props.reloadCompany();
    trackEvent('Updated Card (Billing)');
    return true;
  };

  onUpgrade = (planID) => {
    const isAnnualPlan = [GrowthAnnualPlanID, StarterAnnualPlanID].includes(planID);
    if (isAnnualPlan) {
      this.setState({ modal: Modals.charge, changingPlanID: planID });
      return;
    }

    this.onChangePlan({ confirm: false, planID });
  };

  onUpsellDismiss = () => {
    this.setState({
      modal: null,
      trialPlanID: null,
    });
  };

  getPlanName(planID) {
    if (isStarter(planID)) {
      return PlanNames.starter;
    }

    if (isGrowth(planID)) {
      return PlanNames.growth;
    }

    if (planID === 'business-trial-1') {
      return PlanNames.business;
    }
  }

  // Note: Referral program gives 20% off for 3 months (or %5 for annual plans) however discount is created after user buys a plan
  // So we need to manually mock the discount Discount methods, if user has a referral code and did not buy before
  getCompanyDiscount() {
    const { billingCycle } = this.state;
    const { company } = this.props;
    const { discount, referee } = company;

    if (!discount && (!referee || referee.couponUsed)) {
      return null;
    }

    return discount ?? ReferralDiscounts[billingCycle];
  }

  getPlanDiscount(planName) {
    const discount = this.getCompanyDiscount();

    if (planName === PlanNames.business || !discount) {
      return null;
    }

    const excludedDiscounts = ExcludedDiscounts[planName];
    return !excludedDiscounts.includes(discount.couponID) ? discount : null;
  }

  getUpgradeDiscount(planID) {
    const discount = this.getCompanyDiscount();

    if (!discount) {
      return null;
    }

    const { allowedPlanIDs } = discount;
    if (!allowedPlanIDs || allowedPlanIDs.includes(planID)) {
      return discount;
    }

    return null;
  }

  getSubscription() {
    const {
      billingData: { plan },
    } = this.props.company;

    if (!plan) {
      return 'None';
    }

    if (plan.planID === FreeTrialID) {
      return 'Free Trial';
    }

    if (plan.planID === FreeTierID) {
      return 'Free Plan';
    }

    return 'Active';
  }

  shouldOfferGrowthTrial = () => {
    const { billingData, planTrial } = this.props.company;
    const didGrowthTrial = isGrowth(planTrial?.planID);
    const didBizTrial = planTrial?.planID === BizTrialPlanID;
    const planExpired = !billingData?.plan?.planID;
    const onFree = isFree(billingData?.plan?.planID);
    const onStarter = isStarter(billingData?.plan?.planID);
    return !(didGrowthTrial || didBizTrial) && (planExpired || onFree || onStarter);
  };

  shouldOfferStarterTrial = () => {
    const { billingData, planTrial, trialingPlan } = this.props.company;
    const didStarterTrial = isStarter(planTrial?.planID);
    const onGrowthTrial = isGrowth(trialingPlan?.planID);
    const planExpired = !billingData?.plan?.planID;
    const onFree = isFree(billingData?.plan?.planID);
    return !onGrowthTrial && !didStarterTrial && (planExpired || onFree);
  };

  renderSubscription() {
    return (
      <div className="subscription">
        <div className="label">Subscription:</div>
        <div className="value">{this.getSubscription()}</div>
      </div>
    );
  }

  renderDateText() {
    const { billingData } = this.props.company;
    const { plan } = billingData;
    const label = plan
      ? billingData.cancelled
        ? 'Expiring:'
        : plan.planID === FreeTrialID
        ? 'Trial expires:'
        : 'Next billing date:'
      : 'Expired:';
    return (
      <div className="dateText">
        <div className="label">{label}</div>
        <div className="value">
          {billingData.activeUntil ? (
            <Timestamp showRelative={false} timestamp={billingData.activeUntil.toString()} />
          ) : (
            'Never'
          )}
        </div>
      </div>
    );
  }

  renderInvoices() {
    const { billingData } = this.props.company;
    const { plan } = billingData;
    if (!plan || plan.planID === FreeTrialID || plan.planID === FreeTierID) {
      return null;
    }
    return (
      <div className="invoice">
        <div className="label">Invoices sent to:</div>
        <div className="value">{billingData.invoiceEmail}</div>
        <Tappable onTap={this.onChangeInvoiceEmail}>
          <div className="changeEmail">Change</div>
        </Tappable>
      </div>
    );
  }

  renderCreditCard() {
    const { billingData } = this.props.company;
    const { hasCard, last4 } = billingData;

    if (!hasCard) {
      return (
        <div className="creditCard">
          <div className="label">Credit Card:</div>
          <div className="value">None</div>
          <Tappable onTap={this.onUpdateCard}>
            <div className="changeCard">Add</div>
          </Tappable>
        </div>
      );
    }

    return (
      <div className="creditCard">
        <div className="label">Card ending in:</div>
        <div className="value">{last4}</div>
        <Tappable onTap={this.onUpdateCard}>
          <div className="changeCard">Change</div>
        </Tappable>
      </div>
    );
  }

  renderMonthlySpend() {
    const { billingData, monthlySpend } = this.props.company;
    if (!billingData.plan) {
      return null;
    }

    return (
      <div className="price">
        <div className="label">Price:</div>
        <div className="value">${numberWithCommas(Math.ceil(monthlySpend / 100))}/mo</div>
        <Tappable onTap={this.onShowInvoices}>
          <div className="seeInvoices">See invoice history</div>
        </Tappable>
      </div>
    );
  }

  renderDiscount() {
    const discount = this.getCompanyDiscount();
    if (!discount) {
      return null;
    }

    const amount =
      (discount.amountOff ? '$' : '') +
      (discount.amountOff ? discount.amountOff / 100 : discount.percentOff) +
      (discount.percentOff ? '%' : '') +
      ' off';

    let duration;
    if (discount.duration === 'forever') {
      duration = 'forever';
    } else if (discount.duration === 'once') {
      duration = 'once';
    } else {
      duration = 'for ' + discount.durationInMonths + ' months';
    }

    return (
      <div className="discount">
        <div className="label">Discount:</div>
        <div className="value">{amount + ' ' + duration}</div>
      </div>
    );
  }

  renderCancelSection() {
    const { billingData } = this.props.company;
    const { plan } = billingData;
    if (
      !plan ||
      plan.planID === FreeTrialID ||
      plan.planID === FreeTierID ||
      billingData.cancelled
    ) {
      return null;
    }

    return (
      <div className="cancelSection">
        <div className="endSubscription">End Subscription</div>
        <div className="explanation">
          Upon cancelling, your users will no longer be able to give feedback and your admin
          features will no longer be available.
        </div>
        <Tappable onTap={this.onCancelPlan}>
          <div className="cancelButton">Contact us to cancel your subscription</div>
        </Tappable>
      </div>
    );
  }

  renderChargeModal(planID) {
    const { stats } = this.props.company;
    const { changingPlanID } = this.state;
    const { adminCount } = stats;
    const additionalAdmins = Math.max(0, adminCount - 5);

    const basePrice = isStarter(changingPlanID) ? 79 : 359;
    const adminPrice = isStarter(changingPlanID) ? 18 : 36;
    let charge = (basePrice + additionalAdmins * adminPrice) * 12;

    const discount = this.getUpgradeDiscount(changingPlanID);
    if (discount?.amountOff) {
      charge -= discount.amountOff;
    } else if (discount?.percentOff) {
      charge = Math.round((charge * (100 - discount.percentOff)) / 100);
    }

    const message = `Your credit card will now be billed the annual amount of $${numberWithCommas(
      charge
    )} would you like to continue?`;
    return (
      <ConfirmModal
        closeModal={this.onCloseChargeModal}
        message={message}
        onConfirm={() => this.onChangePlan({ confirm: false, planID: changingPlanID })}
        portalContainerID="adminSettings"
        submitButtonValue="Continue"
        useModalPortal={true}
      />
    );
  }

  renderModal() {
    const { modal } = this.state;

    if (!modal) {
      return;
    }

    if (modal === Modals.charge) {
      return this.renderChargeModal();
    }

    if (modal === Modals.creditCard) {
      return <AdminCCModal onClose={this.onCloseCCModal} onSuccess={this.onSuccessCCModal} />;
    }

    if (modal === Modals.invoices) {
      return <InvoicesModal onClose={this.onCloseInvoices} company={this.props.company} />;
    }

    if (modal === Modals.upsell) {
      const { trialPlanID } = this.state;
      const cta = isStarter(trialPlanID)
        ? 'More power for small businesses'
        : 'Empower your whole team with more powerful features and integrations';
      return (
        <UpsellModal
          cta={cta}
          feature="billing"
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsellDismiss}
          planID={trialPlanID}
          show
        />
      );
    }
  }

  renderBillingInfo() {
    return (
      <>
        {this.renderDateText()}
        {this.renderInvoices()}
        {this.renderCreditCard()}
        {this.renderDiscount()}
      </>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }
    return (
      <div className="error">
        Something went wrong, please try again or{' '}
        <Tappable onTap={this.props.showIntercom}>
          <span className="link">message us for help</span>
        </Tappable>
        .
      </div>
    );
  }

  renderCardError() {
    const { cardError } = this.state;
    if (!cardError) {
      return null;
    }

    return (
      <div className="error">
        {cardError === 'server error' ? (
          <>
            Something went wrong, please try again or{' '}
            <Tappable onTap={this.props.showIntercom}>
              <span className="link">message us for help</span>
            </Tappable>
            .
          </>
        ) : (
          cardError
        )}
      </div>
    );
  }

  renderChangePlanSubmissionError() {
    const { changePlanSubmissionError: error, endPlanSubmissionError } = this.state;
    if (!error && !endPlanSubmissionError) {
      return null;
    }

    let message;
    if (error === changePlanErrors.DoesNotQualify) {
      message = 'You need to disable additional features before proceeding.';
    } else if (error === changePlanErrors.InvalidPlanID) {
      message = (
        <>
          We cannot upgrade your plan,{' '}
          <Tappable onTap={this.props.showIntercom}>
            <span className="link">please message us to proceed</span>
          </Tappable>
          .
        </>
      );
    } else {
      message = (
        <>
          Something went wrong, please try again or{' '}
          <Tappable onTap={this.props.showIntercom}>
            <span className="link">message us for help</span>
          </Tappable>
          .
        </>
      );
    }

    return <div className="error right">{message}</div>;
  }

  renderFreePlans() {
    const { changingPlan, changingPlanID } = this.state;
    const { billingData } = this.props.company;
    const { status } = billingData;
    const { planID } = billingData.plan || {};

    const onFreeLegacy = [FreeV1PlanID, FreeV2PlanID].includes(planID);
    const onFreeAutopilot = planID === FreePlanID;
    const expired = status === 'expired';
    const cta = expired ? 'Get More Time' : 'Use Canny Free';
    const namePill = onFreeLegacy && 'with Autopilot & Private Boards';

    const freePlans = [];
    if (onFreeLegacy) {
      freePlans.push(
        <AdminBillingPlan
          cta={cta}
          description="This plan is deprecated and will not get any feature updates."
          name="Free (Legacy)"
          price={0}
          selected
        />
      );
    }

    freePlans.push(
      <AdminBillingPlan
        cta={cta}
        description="Set up your feedback portal with just the basics"
        disabled={changingPlan}
        name="Free"
        namePill={namePill}
        onSelect={() => this.onChangePlan({ confirm: false, planID: FreePlanID })}
        price={0}
        selected={onFreeAutopilot}
        selecting={changingPlan && changingPlanID === FreePlanID}
      />
    );

    return freePlans;
  }

  renderGrowthPlan() {
    const { billingData, monthlySpend, stats } = this.props.company;
    const { billingCycle, changingPlan, changingPlanID } = this.state;

    const { planID } = billingData.plan || {};
    const { adminCount } = stats;
    const onFree = isFree(planID);
    const onGrowth = isGrowth(planID);

    const discount = this.getPlanDiscount(PlanNames.growth);

    const additionalAdmins = Math.max(0, adminCount - 5);
    const basePrice = billingCycle === 'monthly' ? 399 : 359;
    const additionalAdminPrice = billingCycle === 'monthly' ? 40 : 36;
    let growthUpgradePrice = basePrice + additionalAdmins * additionalAdminPrice;
    if (discount?.amountOff) {
      growthUpgradePrice = Math.max(0, growthUpgradePrice - discount.amountOff);
    } else if (discount?.percentOff) {
      growthUpgradePrice = Math.round((growthUpgradePrice * (100 - discount.percentOff)) / 100);
    }

    const growthPrice = onGrowth ? monthlySpend / 100 : growthUpgradePrice;

    // not gonna show breakdown for growth plans as there is two many combinations of discounts and sliding/nonsliding plans
    const showBreakdown = onFree && additionalAdmins > 0;
    const breakdown = showBreakdown
      ? [
          { label: 'Base price', value: `$${basePrice}/mo` },
          {
            label: 'Additional admins',
            value: `$${additionalAdminPrice} x ${additionalAdmins} admins`,
          },
        ]
      : null;

    const yearlyBilling = billingCycle === 'yearly';

    let timeframe = 'mo';
    if (onGrowth) {
      const isBilledYearly = billingData.plan.timeframe === 'yearly';
      timeframe = isBilledYearly ? 'mo (billed yearly)' : 'mo';
    } else {
      timeframe = yearlyBilling ? 'mo (billed yearly)' : 'mo';
    }

    const description = 'Scale insights across your team with integrations and automations';
    const offerTrial = this.shouldOfferGrowthTrial();
    const upgradePlanID = yearlyBilling ? GrowthAnnualPlanID : GrowthPlanID;
    if (offerTrial) {
      return (
        <AdminBillingPlan
          breakdown={breakdown}
          cta="Start Trial"
          description={description}
          disabled={changingPlan}
          name="Growth"
          onSelect={() => this.onStartTrial(upgradePlanID)}
          price={growthPrice}
          timeframe={timeframe}
        />
      );
    }

    return (
      <AdminBillingPlan
        breakdown={breakdown}
        cta="Upgrade"
        description={description}
        disabled={changingPlan}
        name="Growth"
        onSelect={() => this.onUpgrade(upgradePlanID)}
        price={growthPrice}
        selected={onGrowth}
        selecting={changingPlan && changingPlanID === upgradePlanID}
        timeframe={timeframe}
      />
    );
  }

  renderStarterPlan() {
    const { billingData, monthlySpend, stats } = this.props.company;
    const { billingCycle, changingPlan, changingPlanID } = this.state;

    const { planID } = billingData.plan || {};
    const { adminCount } = stats;
    const onFree = isFree(planID);
    const onGrowth = isGrowth(planID);
    const discount = this.getPlanDiscount(PlanNames.starter);

    const additionalAdmins = Math.max(0, adminCount - 3);
    const basePrice = billingCycle === 'monthly' ? 99 : 79;
    const additionalAdminPrice = billingCycle === 'monthly' ? 20 : 18;
    let starterUpgradePrice = basePrice + additionalAdmins * additionalAdminPrice;
    if (discount?.amountOff) {
      starterUpgradePrice = Math.max(0, starterUpgradePrice - discount.amountOff);
    } else if (discount?.percentOff) {
      starterUpgradePrice = Math.round((starterUpgradePrice * (100 - discount.percentOff)) / 100);
    }

    const onStarter = isStarter(planID);
    const starterPrice = onStarter ? monthlySpend / 100 : starterUpgradePrice;

    // // not gonna show breakdown for starter plans as there is two many combinations of discounts and sliding/nonsliding plans
    const showBreakdown = onFree && additionalAdmins > 0;
    const breakdown = showBreakdown
      ? [
          { label: 'Base price', value: `$${basePrice}/mo` },
          {
            label: 'Additional admins',
            value: `$${additionalAdminPrice} x ${additionalAdmins} admins`,
          },
        ]
      : null;

    const yearlyBilling = billingCycle === 'yearly';
    const upgradePlanID = yearlyBilling ? StarterAnnualPlanID : StarterPlanID;

    let timeframe = 'mo';
    if (onStarter) {
      const isBilledYearly = billingData.plan.timeframe === 'yearly';
      timeframe = isBilledYearly ? 'mo (billed yearly)' : 'mo';
    } else {
      timeframe = yearlyBilling ? 'mo (billed yearly)' : 'mo';
    }

    const description = 'Get more out of your feedback with advanced tools';
    const offerTrial = this.shouldOfferStarterTrial();
    if (offerTrial) {
      return (
        <AdminBillingPlan
          breakdown={breakdown}
          cta="Start Trial"
          description={description}
          disabled={changingPlan}
          name="Starter"
          onSelect={() => this.onStartTrial(upgradePlanID)}
          price={starterPrice}
          timeframe={timeframe}
        />
      );
    }

    const cta = onGrowth ? 'Downgrade' : 'Upgrade';
    return (
      <AdminBillingPlan
        breakdown={breakdown}
        cta={cta}
        description={description}
        disabled={changingPlan}
        name="Starter"
        onSelect={() => this.onUpgrade(upgradePlanID)}
        price={starterPrice}
        selected={onStarter}
        selecting={changingPlan && changingPlanID === upgradePlanID}
        timeframe={timeframe}
      />
    );
  }

  renderPlanCards() {
    const { changingPlan } = this.state;
    const { billingData, monthlySpend } = this.props.company;
    const { isBusiness } = billingData.plan || {};

    const bizDescription = 'Deploy additional permissions, compliance, and customizations';
    if (isBusiness) {
      return (
        <div className="plans">
          <AdminBillingPlan
            description={bizDescription}
            disabled={changingPlan}
            name="Business"
            price={monthlySpend / 100}
            selected
          />
        </div>
      );
    }

    return (
      <div className="plans">
        {this.renderFreePlans()}
        {this.renderStarterPlan()}
        {this.renderGrowthPlan()}
        <AdminBillingPlan
          cta="Contact Us"
          description={bizDescription}
          disabled={changingPlan}
          name="Business"
          onSelect={this.onContactSupport}
          priceCustom
        />
      </div>
    );
  }

  renderPlanBillingCycleToggle() {
    const { billingData, featureAllowlist } = this.props.company;
    const { billingCycle } = this.state;

    const { isBusiness: onBusiness, planID } = billingData.plan || {};

    if (onBusiness) {
      return null;
    }

    // TODO allow monthly plans move to annual
    const onGrowth = isGrowth(planID);
    const isAllowedDowngrade = featureAllowlist.includes('downgrade-plan');
    if (onGrowth && !isAllowedDowngrade) {
      return null;
    }

    return (
      <TextToggle
        onChange={this.onToggleBillingCycle}
        options={[
          {
            label: 'Pay Monthly',
            value: 'monthly',
          },
          {
            label: 'Pay Yearly',
            value: 'yearly',
          },
        ]}
        selected={billingCycle}
      />
    );
  }

  renderPlans() {
    return (
      <div className="plansContainer">
        <div className="plansHeader">
          <div className="header">Plans</div>
          {this.renderPlanBillingCycleToggle()}
        </div>
        {this.renderPlanCards()}
      </div>
    );
  }

  renderChangePlanError() {
    const { changingPlan, changingPlanID, changePlanErrors, lostFeatures } = this.state;

    if (!changePlanErrors && !lostFeatures) {
      return;
    }

    const planName = this.getPlanName(changingPlanID);
    const cta = planName ? `Accept and move to ${planName}` : 'Accept and change plan';
    const warning = planName
      ? `In order to use the Canny ${planName} plan, your team needs to turn off these features:`
      : 'In order to use this Canny plan, your team needs to turn off these features:';

    return (
      <div className="confirmation">
        {changePlanErrors ? <div className="message">{warning}</div> : null}
        <AdminPlanErrors
          lostFeatures={lostFeatures}
          planErrors={changePlanErrors}
          reloadErrors={() => this.onChangePlan({ confirm: false, planID: changingPlanID })}
          isReloading={changingPlan}
        />
        <div className="acceptAndChange">
          <Button
            buttonType="cannyButton"
            loading={changingPlan}
            onTap={() => this.onChangePlan({ confirm: true, planID: changingPlanID })}
            value={cta}
          />
        </div>
        {this.renderChangePlanSubmissionError()}
      </div>
    );
  }

  renderEndPlanTrialError() {
    const { endingPlanTrial, endPlanTrialErrors, planTrialLostFeatures, endPlanSubmissionError } =
      this.state;

    if (!endPlanTrialErrors && !planTrialLostFeatures && !endPlanSubmissionError) {
      return;
    }

    return (
      <div className="confirmation">
        {endPlanTrialErrors && (
          <div className="message">
            In order to end your trial, your team needs to turn off these features:
          </div>
        )}
        <AdminPlanErrors
          lostFeatures={planTrialLostFeatures}
          planErrors={endPlanTrialErrors}
          reloadErrors={() => this.onEndTrial({ confirm: false })}
          isReloading={endingPlanTrial}
        />
        <div className="acceptAndChange">
          <Button
            buttonType="cannyButton"
            loading={endingPlanTrial}
            onTap={() => this.onEndTrial({ confirm: true })}
            value="Accept and end trial"
          />
        </div>
        {this.renderChangePlanSubmissionError()}
      </div>
    );
  }

  renderTrialingPlan() {
    const {
      billingData: { plan },
      planTrial,
    } = this.props.company;
    if (!plan || !planTrial || planTrial.cancelled || planTrial.upgraded) {
      return;
    }

    const planName = getPlanNameForTrial(planTrial);
    const { endingPlanTrial, endPlanTrialErrors, planTrialLostFeatures } = this.state;
    return (
      <div
        className={classnames('trialingPlan', {
          endConfirmation: endPlanTrialErrors || planTrialLostFeatures,
        })}>
        <div className="label">Trialing:</div>
        <div className="value">{planName} Plan</div>
        <Tappable onTap={this.onEndTrial}>
          <div className="endTrial">End trial</div>
        </Tappable>
        {endingPlanTrial && <Spinner />}
      </div>
    );
  }

  renderContent() {
    return (
      <div className="content">
        <div className="currentPlan">
          {this.renderBillingInfo()}
          {this.renderMonthlySpend()}
          {this.renderError()}
          {this.renderCardError()}
        </div>
        {this.renderTrialingPlan()}
        {this.renderEndPlanTrialError()}
        {this.renderPlans()}
        {this.renderChangePlanError()}
        {this.renderCancelSection()}
        {this.renderModal()}
      </div>
    );
  }

  render() {
    return (
      <div className="adminBillingSettings">
        <Helmet title="Plans & Billing | Canny" />
        {this.renderContent()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return Promise.all([dispatch(reloadCompany()), dispatch(reloadAllBoards())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.billing.subscription),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      showIntercom: ShowIntercomContext,
      trackEvent: TrackEventContext,
      viewer: ViewerContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminBillingSettings);
