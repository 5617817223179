import {
  ReviewPlatformLabels,
  ReviewPlatformNames,
  SyncIntegrationNames,
} from 'common/constants/autopilotIntegrations';

import AppStoreLogo from 'img/app-store-logo-small.svg';
import CapterraLogo from 'img/capterra-logo-small.svg';
import G2Logo from 'img/g2-logo-small.svg';
import GooglePlayLogo from 'img/google-play-logo-small.svg';
import HelpscoutLogo from 'img/helpscout-logo.png';
import IntercomLogo from 'img/landing/intercom-logo.svg';
import ZendeskLogo from 'img/landing/zendesk-logo.svg';
import ProductHuntLogo from 'img/product-hunt-logo-small.svg';
import SalesforceLogo from 'img/salesforce-text-logo-small.svg';
import ShopifyLogo from 'img/shopify-logo-small.svg';
import TrustpilotLogo from 'img/trustpilot-logo-small.svg';
import TrustRadiusLogo from 'img/trustradius-logo-small.svg';
import WordpressLogo from 'img/wordpress-logo-small.svg';

export type Integration = {
  label: string;
  name: SyncIntegrationNames;
  icon: string;
  type: Modal;
  needsCountry?: boolean;
  platform?: ReviewPlatformNames;
};

export enum Modal {
  integration = 'integration',
  review = 'review',
}

type InfoPoints = {
  [key in SyncIntegrationNames]?: string[];
} & {
  conversationDefault: string[];
  reviewDefault: string[];
};

export const InfoPoints: InfoPoints = {
  conversationDefault: [
    'Pulls feedback from conversations closed in the past 30 days.',
    'Continuously collects feedback from newly closed conversations.',
    'Uses your current credit balance—no extra charges unless specified.',
  ],
  reviewDefault: [
    'Pulls feedback from reviews left in the last 100 days',
    'Continuously gathers new feedback from new reviews',
    'Uses 1 credit per review, up until your allotted credit limit',
  ],
  [SyncIntegrationNames.zendesk]: [
    'Pulls feedback from tickets closed in the past 30 days.',
    'Continuously collects feedback from newly closed tickets.',
    'Uses your current credit balance—no extra charges unless specified.',
  ],
};

const conversationSources = [
  {
    label: 'Intercom',
    name: SyncIntegrationNames.intercom,
    icon: IntercomLogo,
  },
  {
    label: 'Zendesk',
    name: SyncIntegrationNames.zendesk,
    icon: ZendeskLogo,
  },
  // TODO: in follow-up PR
  //{
  //   label: 'Gong',
  //   name: SyncIntegrationNames.gong,
  //   icon: GongLogo,
  // },
  {
    label: 'Help Scout',
    name: SyncIntegrationNames.helpscout,
    icon: HelpscoutLogo,
  },
];

const mappedConversationSources = conversationSources.reduce(
  (map, { label, name, icon }) => ({
    ...map,
    [name]: {
      label,
      name,
      icon,
      type: Modal.integration,
    },
  }),
  {}
);

const reviewSources = [
  {
    name: SyncIntegrationNames.salesforceReviews,
    label: ReviewPlatformLabels.salesforce,
    icon: SalesforceLogo,
    platform: ReviewPlatformNames.salesforce,
  },
  {
    name: SyncIntegrationNames.appStoreReviews,
    icon: AppStoreLogo,
    platform: ReviewPlatformNames.appStore,
    needsCountry: true,
  },
  {
    name: SyncIntegrationNames.playStoreReviews,
    icon: GooglePlayLogo,
    platform: ReviewPlatformNames.playStore,
    needsCountry: true,
  },
  {
    name: SyncIntegrationNames.g2Reviews,
    icon: G2Logo,
    platform: ReviewPlatformNames.g2,
  },
  {
    name: SyncIntegrationNames.capterraReviews,
    icon: CapterraLogo,
    platform: ReviewPlatformNames.capterra,
  },
  {
    name: SyncIntegrationNames.trustpilotReviews,
    icon: TrustpilotLogo,
    platform: ReviewPlatformNames.trustpilot,
  },
  {
    name: SyncIntegrationNames.trustradiusReviews,
    icon: TrustRadiusLogo,
    platform: ReviewPlatformNames.trustradius,
  },
  {
    name: SyncIntegrationNames.productHuntReviews,
    icon: ProductHuntLogo,
    platform: ReviewPlatformNames.productHunt,
  },
  {
    name: SyncIntegrationNames.wordpressReviews,
    icon: WordpressLogo,
    platform: ReviewPlatformNames.wordpress,
  },
  {
    name: SyncIntegrationNames.shopifyReviews,
    icon: ShopifyLogo,
    platform: ReviewPlatformNames.shopify,
  },
];

const mappedReviewSources = reviewSources.reduce(
  (map, { name, icon, platform, needsCountry, label }) => ({
    ...map,
    [name]: {
      label: label ?? platform,
      name,
      icon,
      platform,
      needsCountry,
      type: Modal.review,
    },
  }),
  {}
);

export const IntegrationMap: Record<string, Integration> = {
  ...mappedConversationSources,
  ...mappedReviewSources,
};

export const Integrations = Object.values(IntegrationMap);
