import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import translateString from 'common/i18n/translateString';
import SearchInput from 'common/inputs/SearchInput';
import Link from 'common/Link';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import capitalizeWords from 'common/util/capitalizeWords';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import delayer from 'common/util/delayer';
import mapify from 'common/util/mapify';
import numberWithCommas from 'common/util/numberWithCommas';
import withContexts from 'common/util/withContexts';

import 'css/components/post/_PostListMenu.scss';

const FilterOptions = {
  my: {
    name: 'my',
    render: 'My\u00a0Own',
    isHidden: (viewer) => !viewer || viewer.loggedOut,
    type: 'filter',
  },
  myCompanies: {
    name: 'myCompanies',
    render: 'My\u00a0Teams',
    isHidden: (viewer) => !viewer || viewer.loggedOut || !viewer.hasThirdPartyMemberships,
    type: 'filter',
  },
};
const SortOptions = {
  trending: {
    name: 'trending',
    render: 'Trending',
    type: 'sort',
  },
  top: {
    name: 'top',
    render: 'Top',
    type: 'sort',
  },
  new: {
    name: 'new',
    render: 'New',
    type: 'sort',
  },
};

class PostListMenu extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    board: PropTypes.object,
    company: PropTypes.shape({
      subdomain: PropTypes.string,
    }),
    location: PropTypes.shape({
      query: PropTypes.shape({
        search: PropTypes.string,
        sort: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
    router: PropTypes.object,
    searchMode: PropTypes.bool.isRequired,
    tintColor: PropTypes.string,
    viewer: PropTypes.object,
  };

  static defaultProps = {
    autoFocus: true,
  };

  state = {
    categoriesMenuOpen: false,
    menuOpen: false,
    searchMode: this.props.searchMode || !!this.props.location.query.search,
  };

  constructor(props, context) {
    super(props, context);

    this._searchDelayer = new delayer(this.onSearchInputChangeAfterDelay, 250);
    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onTouchEnd, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onTouchEnd, false);
    this._searchDelayer.cancel();
  }

  updateQuery(query) {
    const { router, location } = this.props;
    router.replace({ ...location, query });
  }

  getSelectedCategory() {
    const params = new URLSearchParams(
      typeof window !== 'undefined' ? window?.location.search : ''
    );
    const categoryURLName = params.get('category');

    const { board } = this.props;
    if (categoryURLName && board) {
      const { categories } = board;
      return categories.find((category) => category.urlName === categoryURLName);
    }
  }

  getSelectedOption() {
    const params = new URLSearchParams(
      typeof window !== 'undefined' ? window?.location.search : ''
    );
    const filter = params.get('params'),
      sort = params.get('sort'),
      status = params.get('status');

    const options = this.getOptions();

    if (sort && options[sort]) {
      return options[sort];
    } else if (status && options[status]) {
      return options[status];
    } else if (filter && options[filter]) {
      return options[filter];
    } else {
      return options.trending;
    }
  }

  getOptions = () => {
    const { company } = this.props;
    const statuses = company.statuses
      .filter((status) => ![PostStatusTypes.Initial, PostStatusTypes.Closed].includes(status.type))
      .map((status) => {
        const statusName = translateString(status, 'name');
        return {
          name: status.urlName,
          render: capitalizeWords(statusName).replace(' ', '\u00a0'),
          type: 'status',
        };
      });
    const statusOptions = mapify(statuses, 'name');

    const options = {
      ...SortOptions,
      ...statusOptions,
      ...FilterOptions,
    };

    return options;
  };

  onTouchEnd = (e) => {
    if (!findDOMNode(this).contains(e.target)) {
      this.setState({
        categoriesMenuOpen: false,
        menuOpen: false,
      });
      return;
    }
  };

  getCategoryLink(category) {
    const { pathname, query } = this.props.location;

    const { category: existingCategory, ...newQuery } = query;
    if (category) {
      newQuery.category = category.urlName;
    }

    const queryParams = new URLSearchParams(newQuery);
    return `${pathname}?${queryParams.toString()}`;
  }

  generateOptionPath = (optionName) => {
    const { pathname, query } = this.props.location;

    const options = this.getOptions();
    const option = options[optionName];

    // Strip unwanted arguments and add the option
    const { filter, search, sort, status, ...newQuery } = query;
    newQuery[option.type] = optionName;

    const queryParams = new URLSearchParams(newQuery);
    return `${pathname}?${queryParams.toString()}`;
  };

  onCloseSearch = () => {
    this.setState({
      searchMode: false,
    });
    this.searchInputRef.current.setValue('');

    const selectedCategory = this.getSelectedCategory();
    const selectedOption = this.getSelectedOption();
    this.updateQuery({
      category: selectedCategory ? selectedCategory.urlName : undefined,
      search: undefined,
      [selectedOption.type]: selectedOption.name,
    });
  };

  onOpenSearch = () => {
    this.setState(
      {
        searchMode: true,
      },
      () => {
        this.searchInputRef.current.focus();
      }
    );
  };

  onToggleMenu = () => {
    this.setState({
      categoriesMenuOpen: false,
      menuOpen: !this.state.menuOpen,
    });
  };

  onToggleCategoriesMenu = () => {
    this.setState({
      categoriesMenuOpen: !this.state.categoriesMenuOpen,
      menuOpen: false,
    });
  };

  onSearchInputChange = (searchQuery) => {
    if (!searchQuery) {
      const { search, ...newQuery } = this.props.location.query;
      this.updateQuery(newQuery);
      this._searchDelayer.cancel();
      return;
    }

    this._searchDelayer.callAfterDelay(searchQuery);
  };

  onSearchInputChangeAfterDelay = (searchQuery) => {
    const {
      searchMode,
      location: { query },
    } = this.props;
    const encodedSearch = encodeURIComponent(searchQuery);
    this.updateQuery(
      searchMode
        ? {
            ...query,
            search: encodedSearch,
          }
        : {
            search: encodedSearch,
          }
    );
  };

  renderCategoriesMenu() {
    const { board } = this.props;
    if (!board || !board.categories || board.categories.length === 0) {
      return null;
    }

    const selectedCategory = this.getSelectedCategory();
    const selected = (selectedCategory ? selectedCategory.name : 'All Categories').replace(
      ' ',
      '\u00a0'
    );
    return (
      <div className="categoriesMenu">
        <div className="text in">{'\u0020in'}</div>
        <div className="dropdown">
          <button onClick={this.onToggleCategoriesMenu} className="selector">
            <div className="selectedName">{selected}</div>
            <div className="icon-chevron-down" />
          </button>
          {this.renderCategoriesDropdown()}
        </div>
      </div>
    );
  }

  renderCategoriesDropdown() {
    const { categoriesMenuOpen } = this.state;
    if (!categoriesMenuOpen) {
      return null;
    }

    const {
      board: { categories },
    } = this.props;

    const onSelection = () => this.setState({ categoriesMenuOpen: false });

    const options = [
      <Link key={'__all__categories__'} to={this.getCategoryLink(null)} onTap={onSelection}>
        <div className="option">All Categories</div>
      </Link>,
    ];

    categories.forEach((category) => {
      options.push(
        <Link key={category._id} to={this.getCategoryLink(category)} onTap={onSelection}>
          <div
            className={classnames('option', {
              ...(category.parentID ? { subCategory: true } : {}),
            })}>
            {translateString(category, 'name') + ' (' + numberWithCommas(category.postCount) + ')'}
          </div>
        </Link>
      );
    });

    return (
      <div className="dropdownContent">
        <div className="categories">{options}</div>
      </div>
    );
  }

  renderDropdown = () => {
    const { menuOpen } = this.state;
    if (!menuOpen) {
      return null;
    }

    const {
      company: { subdomain },
      tintColor,
      viewer,
    } = this.props;

    const sorts = [];
    const filters = [];

    const selectedOption = this.getSelectedOption();
    const options = this.getOptions();

    for (const optionName in options) {
      const option = options[optionName];
      if (option.isHidden?.(viewer)) {
        continue;
      }
      if (option.subdomainAllowlist && !option.subdomainAllowlist.includes(subdomain)) {
        continue;
      }

      const list = option.type === 'sort' ? sorts : filters;
      const className = 'option' + (option.name === selectedOption.name ? ' selected' : '');
      const onSelection = () => this.setState({ menuOpen: false });

      list.push(
        <Link key={optionName} to={this.generateOptionPath(optionName)} onTap={onSelection}>
          <div className={className}>
            <div
              className="dot"
              style={{
                backgroundColor: tintColor,
              }}
            />
            {option.render}
          </div>
        </Link>
      );
    }

    return (
      <div className="dropdownContent">
        <div className="sorts">
          <UppercaseHeader>Sort</UppercaseHeader>
          {sorts}
        </div>
        <div className="filters">
          <UppercaseHeader>Filter</UppercaseHeader>
          {filters}
        </div>
      </div>
    );
  };

  renderMenu() {
    const selectedOption = this.getSelectedOption();
    if (this.state.searchMode || !selectedOption) {
      return null;
    }

    return (
      <div className="menu">
        <div className="text">Showing</div>
        <div className="dropdown">
          <button onClick={this.onToggleMenu} className="selector">
            <div className="selectedName">{selectedOption.render}</div>
            <div className="icon-chevron-down" />
          </button>
          {this.renderDropdown()}
        </div>
        <div className="text">posts</div>
        {this.renderCategoriesMenu()}
      </div>
    );
  }

  renderDismissSearch() {
    if (!this.state.searchMode) {
      return null;
    }

    return (
      <Tappable onTap={this.onCloseSearch}>
        <div key="x" className="icon icon-x" />
      </Tappable>
    );
  }

  renderSearchMode() {
    var className = 'searchContainer';
    const { searchMode } = this.state;
    if (searchMode) {
      className += ' active';
    }
    const { autoFocus, location } = this.props;
    const textSearch = decodeQueryTextParam(location.query.search);
    return (
      <div className={className}>
        <Tappable onTap={this.onOpenSearch}>
          <div className="searchBar">
            <SearchInput
              autoFocus={searchMode && autoFocus}
              defaultValue={textSearch}
              onChange={this.onSearchInputChange}
              placeholder={'Search…'}
              ref={this.searchInputRef}
              showClearIcon={false}
              stopPropagation={false}
            />
          </div>
        </Tappable>
        {this.renderDismissSearch()}
      </div>
    );
  }

  render() {
    return (
      <div className="postListMenu">
        {this.renderMenu()}
        {this.renderSearchMode()}
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  router: RouterContext,
  tintColor: TintColorContext,
  viewer: ViewerContext,
})(PostListMenu);
