import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import Dropdown from 'common/Dropdown';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Message from 'common/message/Message';
import ConfirmModal from 'common/modals/ConfirmModal';
import DefaultCompanyRoleNames from 'common/roles/CompanyRoleNames';
import { companyRoleHasBillablePermissions } from 'common/roles/utils';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import Alert, { AlertTypes } from 'common/ui/Alert';
import SwitchV2 from 'common/ui/SwitchV2';
import { H1, P } from 'common/ui/Text';
import devURL from 'common/util/devURL';
import getHostname from 'common/util/getHostname';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminOktaSettings.scss';

const FakePlaceholderSecret = '••••••••••••••••••••••••••••••••••••••••';
const HelpGuideLink = 'https://help.canny.io/en/articles/4029303-okta-sso-integration';
const MessageOrigin = devURL('https://canny.io');

const OktaErrorMessages = {
  'invalid oktaDomain or clientID': 'Either the Okta Domain or Client ID is invalid.',
  'invalid oktaDomain': 'Please enter a valid Okta domain.',
};

class AdminOktaSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      subdomain: PropTypes.string,
      okta: PropTypes.shape({
        clientID: PropTypes.string,
        defaultAccessLevel: PropTypes.string,
        oktaDomain: PropTypes.string,
      }),
    }),
    openModal: PropTypes.func,
  };

  state = {
    // Use defaultAccessLevel if defined, or fall back to contributor/owner
    defaultAccessLevel:
      this.props.company.okta?.defaultAccessLevel ||
      (this.props.company.features.adminRoles
        ? DefaultCompanyRoleNames.contributor.toLowerCase()
        : DefaultCompanyRoleNames.owner.toLowerCase()),
    error: null,
    forcingMemberAuth: this.props.company.forcedMemberAuthMechanism === 'okta',
    hasChangedAuthSettings: false,
    hasChangedMemberAuth: false,
    hasChangedRoleSettings: false,
    isInstalled: !!this.props.company.okta,
    oktaDomain: this.props.company.okta ? this.props.company.okta.oktaDomain : '',
    clientID: this.props.company.okta ? this.props.company.okta.clientID : '',
    clientSecret: this.props.company.okta ? FakePlaceholderSecret : '',
    loadingConnect: false,
    loadingDisconnect: false,
  };

  constructor(props, context) {
    super(props, context);

    this.clientSecretRef = React.createRef();
  }

  checkClientSecret = () => {
    // Clear the placeholder secret if the config was modified
    const shouldClear = this.state.clientSecret === FakePlaceholderSecret;

    if (shouldClear) {
      this.setState({ clientSecret: '' }, () => {
        this.clientSecretRef.current.setValue('');
      });
    }
  };

  doesPlanSupport() {
    const { company } = this.props;
    return company?.integrations?.okta;
  }

  onClientIDChange = (e) => {
    this.setState({
      error: null,
      hasChangedAuthSettings: true,
      clientID: e.target.value,
    });
    this.checkClientSecret();
  };

  onClientSecretChange = (e) => {
    this.setState({
      error: null,
      hasChangedAuthSettings: true,
      clientSecret: e.target.value,
    });
  };

  onOktaDomainChange = (e) => {
    this.setState({
      error: null,
      hasChangedAuthSettings: true,
      oktaDomain: e.target.value,
    });
    this.checkClientSecret();
  };

  onConnect = async () => {
    if (this.state.loadingConnect) {
      return;
    }
    this.setState({ loadingConnect: true });

    const {
      clientID,
      clientSecret,
      defaultAccessLevel,
      forcingMemberAuth,
      hasChangedAuthSettings,
      hasChangedMemberAuth,
      oktaDomain,
    } = this.state;

    const promises = [
      AJAX.post('/api/okta/register', {
        ...(hasChangedAuthSettings && {
          clientID,
          clientSecret,
          oktaDomain,
        }),
        defaultAccessLevel,
      }),
    ];

    if (hasChangedMemberAuth) {
      promises.push(
        AJAX.post('/api/company/changeSettings', {
          forcedMemberAuthMechanism: forcingMemberAuth ? 'okta' : 'none',
        })
      );
    }

    const responses = await Promise.all(promises);
    let responseError;

    responses.forEach((response) => {
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: OktaErrorMessages,
      });
      responseError = error ? error : responseError;
    });

    if (responseError) {
      this.setState({
        loadingConnect: false,
        error: responseError.message,
      });
      return;
    }

    const startedForcingMembers = forcingMemberAuth && hasChangedMemberAuth;

    if (!hasChangedAuthSettings && !startedForcingMembers) {
      this.setState({
        hasChangedMemberAuth: false,
        hasChangedRoleSettings: false,
        loadingConnect: false,
      });
      return;
    }

    this.startValidation();
  };

  onDisconnect = () => {
    const { openModal } = this.props;
    if (this.state.loadingDisconnect) {
      return;
    }

    openModal(ConfirmModal, {
      message: `Are you sure you'd like to disconnect your Okta organization?`,
      onConfirm: () => {
        this.setState({ loadingDisconnect: true });

        AJAX.post('/api/okta/disconnect', {}, async (response) => {
          this.setState({ loadingDisconnect: false });

          if (response !== 'success') {
            this.setState({
              error: 'Something went wrong, please contact our team for support.',
            });
            return;
          }
          this.setState({ isInstalled: false });
        });
      },
    });
  };

  onRoleChange = (defaultAccessLevel) => {
    this.setState({
      defaultAccessLevel,
      hasChangedRoleSettings: true,
    });
  };

  onToggleForcingMemberAuth = () => {
    this.setState((state) => ({
      forcingMemberAuth: !state.forcingMemberAuth,
      hasChangedMemberAuth: true,
    }));
  };

  startValidation() {
    const oktaHostname = getHostname(this.state.oktaDomain);
    const initiateUrl = devURL(
      `https://canny.io/api/oauth/okta/initiate?iss=https%3A%2F%2F${oktaHostname}&validateConfig=true`
    );
    // No, 'noopener' because we trust business customer admins to not try to phish their owner users
    const oktaWindow = window.open(initiateUrl, '_blank');

    const unsubscribe = Message.subscribe(oktaWindow, MessageOrigin, 'oktaValidation', (result) => {
      unsubscribe();
      let error = null;

      if (result.validation === 'invalid') {
        error = 'The Client Secret seems to be invalid.';
      } else if (result.validation === 'error') {
        error = 'An unknown error occurred.';
      }

      this.setState({
        loadingConnect: false,
        isInstalled: true,
        error,
        hasChangedAuthSettings: error ? true : false,
        hasChangedMemberAuth: error ? true : false,
        hasChangedRoleSettings: false,
      });
    });
  }

  renderInfo() {
    if (!this.doesPlanSupport()) {
      return null;
    }

    return (
      <div className="infoSection">
        <div className="subheading">Authenticate your teammates into Canny</div>
        <p className="text">
          Grant your teammates access to Canny by signing in with their company Okta account.
        </p>
      </div>
    );
  }

  renderSettings() {
    if (!this.doesPlanSupport()) {
      return null;
    }

    const customRoles = this.props.company.roles
      .filter(({ name }) => !Object.values(DefaultCompanyRoleNames).includes(name))
      .map((role) => ({
        key: role.name.toLowerCase(),
        needsAdminRoles: true,
        render: role.name,
      }));

    const roles = [
      {
        key: 'user',
        needsAdminRoles: false,
        render: 'User',
      },
      {
        key: DefaultCompanyRoleNames.contributor.toLowerCase(),
        needsAdminRoles: true,
        render: 'Contributor',
      },
      {
        key: DefaultCompanyRoleNames.manager.toLowerCase(),
        needsAdminRoles: true,
        render: 'Manager',
      },
      {
        key: DefaultCompanyRoleNames.owner.toLowerCase(),
        needsAdminRoles: false,
        render: 'Owner',
      },
      ...customRoles,
    ];

    const {
      clientID,
      clientSecret,
      defaultAccessLevel,
      forcingMemberAuth,
      hasChangedAuthSettings,
      hasChangedMemberAuth,
      hasChangedRoleSettings,
      oktaDomain,
      loadingConnect,
      loadingDisconnect,
      isInstalled,
    } = this.state;

    const { company } = this.props;

    const canSubmit =
      oktaDomain &&
      clientID &&
      clientSecret &&
      !loadingDisconnect &&
      (hasChangedAuthSettings || hasChangedRoleSettings || hasChangedMemberAuth);

    const selectedRole = company.roles.find(
      (role) => role.name.toLowerCase() === defaultAccessLevel
    );
    return (
      <Form
        addEventsToDocument={false}
        disableSubmit={!canSubmit || loadingConnect}
        onSubmit={this.onConnect}>
        <div className="inputs">
          <div className="inputSection">
            <p className="text">
              Enter your Okta details to install the integration. Check out our{' '}
              <a
                className="link"
                href={HelpGuideLink}
                target="_blank"
                rel="nofollow noreferrer noopener">
                setup guide
              </a>{' '}
              for more info. The app client ID and client secret can be found on the General
              settings page of the Canny app you installed.
            </p>
            <TextInput
              inset="Okta Domain"
              defaultValue={oktaDomain}
              onChange={this.onOktaDomainChange}
              prefix="https://"
              placeholder="your-auth-server.okta.com"
            />
          </div>
          <div className="inputSection">
            <TextInput
              inset="App Client ID"
              defaultValue={clientID}
              onChange={this.onClientIDChange}
            />
            <TextInput
              ref={this.clientSecretRef}
              inset="App Client Secret"
              defaultValue={clientSecret}
              onFocus={this.checkClientSecret}
              onChange={this.onClientSecretChange}
            />
            <p className="text">
              Select which role you would like users to be assigned after successfully
              authenticating via&nbsp;Okta:
            </p>
            <Dropdown
              className="roleDropdown"
              defaultSelectedName={defaultAccessLevel}
              onChange={this.onRoleChange}
              options={roles
                .filter((role) => {
                  return role.needsAdminRoles ? company.features.adminRoles : true;
                })
                .map((role) => {
                  return {
                    name: role.key,
                    render: role.render,
                  };
                })}
            />
            {selectedRole &&
              company.sliding?.admins &&
              companyRoleHasBillablePermissions(company, selectedRole) && (
                <Alert
                  type={AlertTypes.Danger}
                  headingText="Billing alert"
                  subText={`The role "${selectedRole.name}" is billed per-admin and will incur additional charges.`}
                />
              )}
            <div className="forceAuthContainer">
              <div className="settingsDetails">
                <H1 variant="headingMd">Enforce Okta login</H1>
                <P className="detailsBody" variant="bodyMd">
                  Users are required to log in with Okta to access Canny admin functionality.
                </P>
              </div>
              <SwitchV2 onChange={this.onToggleForcingMemberAuth} checked={forcingMemberAuth} />
            </div>
          </div>
        </div>
        <div className="buttons">
          <Button
            disabled={!canSubmit}
            formButton={true}
            loading={loadingConnect}
            value={isInstalled ? 'Update' : 'Connect Okta'}
          />
          {isInstalled && (
            <Button
              className="disconnectButton"
              onTap={this.onDisconnect}
              loading={loadingDisconnect}
              value="Disconnect"
            />
          )}
        </div>
      </Form>
    );
  }

  renderUpsell() {
    if (this.doesPlanSupport()) {
      return null;
    }

    return (
      <AdminFeatureBlock
        feature="Okta Integration"
        benefit="Authenticate your teammates as Canny Admins."
        showBillingLink={false}
      />
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error text">{this.state.error}</div>;
  }

  render() {
    return (
      <div className="adminOktaSettings">
        <Helmet title="Okta Integration | Canny" />
        <div className="settingsHeading">Okta Integration</div>
        <div className="content">
          {this.renderInfo()}
          {this.renderSettings()}
          {this.renderError()}
          {this.renderUpsell()}
        </div>
      </div>
    );
  }
}

export default compose(
  withAccessControl(testEveryPermission(RoutePermissions.integrations.okta), '/admin/settings'),
  withContexts({
    company: CompanyContext,
    openModal: OpenModalContext,
  })
)(AdminOktaSettings);
