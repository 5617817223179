import { useContext } from 'react';

import { type QueryParams as InboxQueryParams } from 'common/actions/inboxItemQueries';
import { type QueryParams as QueueQueryParams } from 'common/actions/queueItemQueries';
import AJAX from 'common/AJAX';
import {
  type EncodedQuery as InboxEncodedQuery,
  getEncodedQueryParams as getInboxEncodedQueryParams,
  getQueryParams as getInboxQueryParams,
} from 'common/containers/InboxItemListContainer';
import {
  type EncodedQuery as QueueEncodedQuery,
  getPreferencesFromQueryParams,
  getEncodedQueryParams as getQueueEncodedQueryParams,
  getQueryParams as getQueueQueryParams,
} from 'common/containers/QueueItemListContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';

type UnifiedEncodedQuery = QueueEncodedQuery & InboxEncodedQuery;
type UnifiedQueryParams = QueueQueryParams & InboxQueryParams;

type Props = {
  // this is the queryParams from the redux store, used to avoid the delays to location.query updates caused by asyncConnect
  // this allows real-time updates to the filter controls when the user changes the filters
  queryState: UnifiedEncodedQuery | null;
  reloadCompany: () => void;
};

const getQueryParams = ({
  pathname,
  query,
}: {
  pathname: string;
  query: UnifiedEncodedQuery;
}): UnifiedQueryParams => {
  const { sort, ...strippedQuery } = query;
  // we need to extract the sort from the encoded query and add it back to the query params
  // to avoid type errors since it has different types between queue and inbox

  return {
    ...getQueueQueryParams({ pathname, query: strippedQuery }),
    ...getInboxQueryParams({ pathname, query: strippedQuery }),
    sort: sort ?? undefined,
  };
};

const getEncodedUnifiedQueryParams = (queryParams: UnifiedQueryParams): UnifiedEncodedQuery => {
  // we need to extract the sort from the query params and add it back to the encoded query
  // to avoid type errors since it has different types between queue and inbox
  const { sort, ...strippedQueryParams } = queryParams;

  return {
    ...getQueueEncodedQueryParams(strippedQueryParams),
    ...getInboxEncodedQueryParams(strippedQueryParams),
    sort: sort ?? undefined,
  };
};

const useFilterControls = ({ queryState, reloadCompany }: Props) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  // queryState can be null on load
  const currentQuery = queryState ?? location.query;

  const queryParams = getQueryParams({ pathname: location.pathname, query: currentQuery });

  const updateQueuePreferences = async (params: UnifiedQueryParams) => {
    await AJAX.post('/api/viewer/updatePreferences', {
      preferences: {
        queueFilters: getPreferencesFromQueryParams(params),
      },
    });
    reloadCompany();
  };

  const updateFilters = (newParams: Partial<UnifiedQueryParams>) => {
    const updatedParams = { ...queryParams, ...newParams };
    updateQueuePreferences(updatedParams);

    router.replace({
      pathname: location.pathname,
      query: getEncodedUnifiedQueryParams(updatedParams),
    });
  };

  return {
    updateFilters,
    queryParams,
  };
};

export default useFilterControls;
