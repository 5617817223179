import React from 'react';

import { IndexRedirect, IndexRoute, Redirect, Route } from 'react-router';

import ActiveIntegrationsContainer from 'common/containers/ActiveIntegrationsContainer';
import AdminContainer from 'common/containers/AdminContainer';
import AsanaSettingsContainer from 'common/containers/AsanaSettingsContainer';
import AutopilotActionItemListContainer from 'common/containers/AutopilotActionItemListContainer';
import AzureDevopsSettingsContainer from 'common/containers/AzureDevopsSettingsContainer';
import BoardContainer from 'common/containers/BoardContainer';
import BoardsContainer from 'common/containers/BoardsContainer';
import ChangelogContainer from 'common/containers/ChangelogContainer';
import ChangelogEntryContainer from 'common/containers/ChangelogEntryContainer';
import ChangelogEntryListContainer from 'common/containers/ChangelogEntryListContainer';
import CompanyNotificationsContainer from 'common/containers/CompanyNotificationsContainer';
import CustomFieldsContainer from 'common/containers/CustomFieldsContainer';
import CustomPostFieldsContainer from 'common/containers/CustomPostFieldsContainer';
import DiscordSettingsContainer from 'common/containers/DiscordSettingsContainer';
import FreshdeskSettingsContainer from 'common/containers/FreshdeskSettingsContainer';
import GongSettingsContainer from 'common/containers/GongSettingsContainer';
import HelpscoutSettingsContainer from 'common/containers/HelpscoutSettingsContainer';
import InboxItemListContainer from 'common/containers/InboxItemListContainer';
import LinearSettingsContainer from 'common/containers/LinearSettingsContainer';
import PostActivityContainer from 'common/containers/PostActivityContainer';
import PostContainer from 'common/containers/PostContainer';
import PostListContainer from 'common/containers/PostListContainer';
import PostVoterContainer from 'common/containers/PostVoterContainer';
import PublicContainer from 'common/containers/PublicContainer';
import PublicRoadmapContainer from 'common/containers/PublicRoadmapContainer';
import QueueItemListContainer from 'common/containers/QueueItemListContainer';
import ReferralSettingsContainer from 'common/containers/ReferralSettingsContainer';
import RoadmapContainer from 'common/containers/RoadmapContainer';
import RoadmapPostsContainer from 'common/containers/RoadmapPostsContainer';
import RoadmapsContainer from 'common/containers/RoadmapsContainer';
import SharedRoadmapContainer from 'common/containers/SharedRoadmapContainer';
import SharedRoadmapPostsContainer from 'common/containers/SharedRoadmapPostsContainer';
import SubdomainContainer from 'common/containers/SubdomainContainer';
import ThirdPartyCompanyContainer from 'common/containers/ThirdPartyCompanyContainer';
import ThirdPartyCompanyListContainerV2 from 'common/containers/ThirdPartyCompanyListContainerV2';
import TPCForFiltersContainer from 'common/containers/TPCForFiltersContainer';
import UserContainer from 'common/containers/UserContainer';
import UserListContainer from 'common/containers/UserListContainer';
import ZoomSettingsContainer from 'common/containers/ZoomSettingsContainer';
import ExternalRedirect from 'common/core/ExternalRedirect';
import AdminAsanaSettings from 'common/subdomain/admin/AdminAsanaSettings';
import AdminAutopilotSettings from 'common/subdomain/admin/AdminAutopilotSettings';
import AdminFeedbackDiscoverySettings from 'common/subdomain/admin/AdminAutopilotSettings/AdminFeedbackDiscoverySettings';
import AdminKnowledgeHubSettings from 'common/subdomain/admin/AdminAutopilotSettings/AdminKnowledgeHubSettings';
import AdminDiscordSettings from 'common/subdomain/admin/AdminDiscordSettings';
import AdminFreshdeskSettings from 'common/subdomain/admin/AdminFreshdeskSettings';
import AdminGongSettings from 'common/subdomain/admin/AdminGongSettings';
import AdminHelpscoutSettings from 'common/subdomain/admin/AdminHelpscoutSettings';
import AdminLinearSettings from 'common/subdomain/admin/AdminLinearSettings';
import AdminNotifications from 'common/subdomain/admin/AdminNotifications';
import AdminZoomSettings from 'common/subdomain/admin/AdminZoomSettings';
import RegistrationWrapper from 'common/subdomain/RegistrationWrapper';
import SubdomainFourOhFour from 'common/SubdomainFourOhFour';
import devURL from 'common/util/devURL';

import AdminAccountSettings from './admin/AdminAccountSettings';
import AdminAPISettings from './admin/AdminAPISettings';
import AdminAuthSettings from './admin/AdminAuthSettings';
import AdminAutomationSettings from './admin/AdminAutomationSettings';
import AzureDevopsSettings from './admin/AdminAzureDevopsSettings';
import AdminAzureSettings from './admin/AdminAzureSettings';
import AdminBoardSettingsCategories from './admin/AdminBoardSettingsCategories';
import AdminBoardSettingsContainer from './admin/AdminBoardSettingsContainer';
import AdminBoardSettingsCreateForm from './admin/AdminBoardSettingsCreateForm';
import AdminBoardSettingsDataExport from './admin/AdminBoardSettingsDataExport';
import AdminBoardSettingsDataImport from './admin/AdminBoardSettingsDataImport';
import AdminBoardSettingsDelete from './admin/AdminBoardSettingsDelete';
import AdminBoardSettingsGeneral from './admin/AdminBoardSettingsGeneral';
import AdminBoardSettingsPrivacy from './admin/AdminBoardSettingsPrivacy';
import AdminBoardSettingsSmartReplies from './admin/AdminBoardSettingsSmartReplies';
import AdminBoardSettingsTags from './admin/AdminBoardSettingsTags';
import AdminBoardSettingsWidget from './admin/AdminBoardSettingsWidget';
import AdminBoardV1Redirect from './admin/AdminBoardV1Redirect';
import AdminChangelog from './admin/AdminChangelog';
import AdminChangelogCreate from './admin/AdminChangelogCreate';
import AdminChangelogSettingsContainer from './admin/AdminChangelogSettingsContainer';
import AdminChangelogSettingsLabels from './admin/AdminChangelogSettingsLabels';
import AdminChangelogSettingsPrivacy from './admin/AdminChangelogSettingsPrivacy';
import AdminClickupSettings from './admin/AdminClickupSettings';
import AdminCNAMESettings from './admin/AdminCNAMESettings';
import AdminCompanyFieldsSettings from './admin/AdminCompanyFieldsSettings';
import AdminCreateBoard from './admin/AdminCreateBoard';
import AdminCustomPostFieldsSettings from './admin/AdminCustomPostFieldsSettings';
import AdminDashboard from './admin/AdminDashboard/AdminDashboard';
import AdminEmailSettings from './admin/AdminEmailSettings';
import AdminFeedback from './admin/AdminFeedback';
import AdminFieldsSettingsContainer from './admin/AdminFieldsSettingsContainer';
import AdminGeneralBrandingSettings from './admin/AdminGeneralBrandingSettings';
import AdminGeneralPreferencesSettings from './admin/AdminGeneralPreferencesSettings';
import AdminGeneralSettingsContainer from './admin/AdminGeneralSettingsContainer';
import AdminGeneralSettingsDeletion from './admin/AdminGeneralSettingsDeletion';
import AdminGitHubSettings from './admin/AdminGitHubSettings';
import AdminGoogleAnalyticsSettings from './admin/AdminGoogleAnalyticsSettings';
import AdminGSuiteSettings from './admin/AdminGSuiteSettings';
import AdminHubspotSettings from './admin/AdminHubspotSettings';
import AdminIntegrationsSettings from './admin/AdminIntegrationSettings';
import AdminIntercomSettings from './admin/AdminIntercomSettings';
import AdminJiraSettings from './admin/AdminJiraSettings';
import AdminLanguageSettings from './admin/AdminLanguageSettings';
import {
  AdminMemberPeopleSettings,
  AdminMemberRoleSettings,
  AdminMemberSettingsContainer,
} from './admin/AdminMemberSettings';
import AdminMicrosoftTeamsSettings from './admin/AdminMicrosoftTeamsSettings';
import AdminNotificationsSettingsContainer from './admin/AdminNotificationsSettingsContainer';
import AdminOIDCSettings from './admin/AdminOIDCSettings';
import AdminOktaSettings from './admin/AdminOktaSettings';
import AdminOnboarding, {
  AdminOnboardingCreateBoard,
  AdminOnboardingHow,
  AdminOnboardingInviteTeam,
  AdminOnboardingRedirect,
  AdminOnboardingSource,
  AdminOnboardingWhat,
  AdminOnboardingWhere,
} from './admin/AdminOnboarding';
import AdminOneLoginSettings from './admin/AdminOneLoginSettings';
import AdminPostV1Redirect from './admin/AdminPostV1Redirect';
import AdminPreferencesSettings from './admin/AdminPreferencesSettings';
import AdminQueue, { AdminQueueSwitch } from './admin/AdminQueue';
import AdminAutopilotGettingStarted from './admin/AdminQueue/GettingStarted/AdminAutopilotGettingStarted';
import AdminReferralSettings from './admin/AdminReferralSettings';
import AdminRoadmap from './admin/AdminRoadmap';
import AdminRoadmapSettingsArchive from './admin/AdminRoadmapSettingsArchive';
import AdminRoadmapSettingsContainer from './admin/AdminRoadmapSettingsContainer';
import AdminRoadmapSettingsPublicView from './admin/AdminRoadmapSettingsPublicView';
import AdminSalesforceSettings from './admin/AdminSalesforceSettings';
import AdminSavedFiltersSettings from './admin/AdminSavedFiltersSettings';
import AdminSegmentSettings from './admin/AdminSegmentSettings';
import AdminSelectUseCase from './admin/AdminSelectUseCase';
import AdminSettings from './admin/AdminSettings';
import {
  AdminBoardSettingsRedirect,
  AdminChangelogSettingsRedirect,
  AdminFieldsSettingsRedirect,
  AdminGeneralSettingsRedirect,
  AdminNotificationSettingsRedirect,
  AdminRoadmapStatusSettingsRedirect,
  AdminTeamSettingsRedirect,
} from './admin/AdminSettingsRedirects';
import AdminSlackSettings from './admin/AdminSlackSettings';
import AdminStatusSettings from './admin/AdminStatusSettings';
import AdminUserEmailSettingsDomain from './admin/AdminUserEmailSettingsDomain';
import AdminUserEmailSettingsTypes from './admin/AdminUserEmailSettingsTypes';
import AdminUsers from './admin/AdminUsers';
import AdminZapierSettings from './admin/AdminZapierSettings';
import AdminZendeskSettings from './admin/AdminZendeskSettings';
import AdminAutopilotBillingSettings from './admin/billing/AdminAutopilotBillingSettings';
import AdminBillingSettings from './admin/billing/AdminBillingSettings';
import AdminBillingSettingsContainer from './admin/billing/AdminBillingSettingsContainer';
import BoardHome from './public/BoardHome';
import SharedRoadmap from './public/SharedRoadmap';
import SubdomainAccountSettings from './public/SubdomainAccountSettings';
import SubdomainChangelog from './public/SubdomainChangelog';
import SubdomainChangelogEntry from './public/SubdomainChangelogEntry';
import SubdomainCreatePost from './public/SubdomainCreatePost';
import SubdomainHome from './public/SubdomainHome';
import SubdomainJoinCompany from './public/SubdomainJoinCompany';
import SubdomainNotifications from './public/SubdomainNotifications';
import SubdomainPost from './public/SubdomainPost';
import SubdomainPostVotersList from './public/SubdomainPostVotersList';
import SubdomainSearch from './public/SubdomainSearch';
import SubdomainUnsubscribe from './public/SubdomainUnsubscribe';
import SubdomainUnsubscribeReport from './public/SubdomainUnsubscribeReport';

export default (
  <Route path="/" component={SubdomainContainer}>
    {/* admin */}
    <Route path="register" component={RegistrationWrapper}>
      <Route path="use-case" component={AdminSelectUseCase} />
      <Route path="create-board" component={AdminCreateBoard} />
      <IndexRedirect to="/register/use-case" />
    </Route>

    <Route path="/admin/onboarding" component={BoardsContainer}>
      <Route component={AdminOnboarding}>
        <Route path="what" component={AdminOnboardingWhat} />
        <Route path="how" component={AdminOnboardingHow} />
        <Route path="where" component={AdminOnboardingWhere} />
        <Route path="source" component={AdminOnboardingSource} />
        <Route path="create-board" component={AdminOnboardingCreateBoard} />
        <Route path="invite-team" component={AdminOnboardingInviteTeam} />
        <Route path="redirect" component={AdminOnboardingRedirect} />
        <IndexRedirect to="/admin/onboarding/what" />
      </Route>
    </Route>

    <Route path="admin" component={AdminContainer}>
      <Redirect from="inbox" to="autopilot" />

      <Route component={BoardsContainer}>
        <Route component={PublicRoadmapContainer}>
          <Route component={RoadmapsContainer}>
            <IndexRoute component={AdminDashboard} />
          </Route>
        </Route>
      </Route>

      <Route path="boards">
        <IndexRedirect to="/admin" />
      </Route>

      <Route component={BoardsContainer} path="feedback(/:boardURLName/p/:postURLName)">
        <Route component={PostListContainer}>
          <Route component={TPCForFiltersContainer}>
            <Route component={RoadmapsContainer}>
              <Route component={BoardContainer}>
                <Route component={PostContainer}>
                  <Route component={PostActivityContainer}>
                    <Route component={CustomPostFieldsContainer}>
                      <Route component={InboxItemListContainer}>
                        <IndexRoute component={AdminFeedback} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="notifications" component={CompanyNotificationsContainer}>
        <Route component={BoardsContainer}>
          <IndexRoute component={AdminNotifications} />
        </Route>
      </Route>

      <Route path="users" component={UserListContainer}>
        <Route component={BoardsContainer}>
          <Route component={CustomFieldsContainer}>
            <Route component={ThirdPartyCompanyListContainerV2}>
              <IndexRoute component={AdminUsers} />
              <Route path="company/:companyURLName" component={ThirdPartyCompanyContainer}>
                <IndexRoute component={AdminUsers} />
              </Route>
              <Route path=":userURLName" component={UserContainer}>
                <IndexRoute component={AdminUsers} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="roadmap" component={RoadmapsContainer}>
        <Route component={RoadmapContainer}>
          <Route component={CustomPostFieldsContainer}>
            <IndexRoute component={AdminRoadmap} />
            <Route
              path=":roadmapURLName(/:roadmapPostBoardURLName/p/:postURLName)"
              component={RoadmapPostsContainer}>
              <Route component={BoardsContainer}>
                <IndexRoute component={AdminRoadmap} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="board/:boardURLName" component={BoardContainer}>
        <Route component={PostListContainer}>
          <IndexRoute component={AdminBoardV1Redirect} />
        </Route>

        <Route component={PostListContainer}>
          <Route path="create" component={SubdomainCreatePost} />
        </Route>

        <Route path="p/:postURLName" component={PostContainer}>
          <IndexRoute component={AdminPostV1Redirect} />
        </Route>
      </Route>

      <Route path="autopilot">
        <Route component={ActiveIntegrationsContainer}>
          <Route path="getting-started" component={AdminAutopilotGettingStarted} />
        </Route>
        <Route component={BoardsContainer}>
          <Route component={CustomFieldsContainer}>
            <Route component={QueueItemListContainer}>
              <Route component={InboxItemListContainer}>
                <Route component={AutopilotActionItemListContainer}>
                  <Route component={AdminQueue}>
                    <Route component={CustomPostFieldsContainer}>
                      <Route component={RoadmapsContainer}>
                        <IndexRoute component={AdminQueueSwitch} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="changelog" component={ChangelogContainer}>
        <Route component={ChangelogEntryListContainer}>
          <IndexRoute component={AdminChangelog} />
        </Route>

        <Route path=":entryURLName/edit" component={ChangelogEntryContainer}>
          <IndexRoute component={AdminChangelogCreate} />
        </Route>

        <Route path="create" component={ChangelogEntryContainer}>
          <IndexRoute component={AdminChangelogCreate} />
        </Route>
      </Route>

      <Route path="create-board" component={AdminCreateBoard} />

      <Route path="settings" component={AdminSettings}>
        <IndexRedirect to="account" />

        <Route path="account" component={AdminAccountSettings} />
        <Route path="api" component={AdminAPISettings} />
        <Route component={BoardsContainer}>
          <Route component={RoadmapsContainer}>
            <Route component={CustomPostFieldsContainer}>
              <Route path="automation" component={AdminAutomationSettings} />
            </Route>
          </Route>
          <Route path="boards" component={AdminBoardSettingsContainer}>
            <Route path=":boardURLName" component={BoardContainer}>
              <IndexRoute component={AdminBoardSettingsRedirect} />
              <Route path="general" component={AdminBoardSettingsGeneral} />
              <Route component={CustomPostFieldsContainer}>
                <Route path="create-form" component={AdminBoardSettingsCreateForm} />
              </Route>
              <Route path="privacy" component={AdminBoardSettingsPrivacy} />
              <Route path="tags" component={AdminBoardSettingsTags} />
              <Route path="widget" component={AdminBoardSettingsWidget} />
              <Route path="categories" component={AdminBoardSettingsCategories} />
              <Route path="data-import" component={AdminBoardSettingsDataImport} />
              <Route path="data-export" component={AdminBoardSettingsDataExport} />
              <Route path="smart-replies" component={AdminBoardSettingsSmartReplies} />
              <Route path="delete" component={AdminBoardSettingsDelete} />
            </Route>
          </Route>
          <Route component={DiscordSettingsContainer}>
            <Route path="billing" component={AdminBillingSettingsContainer}>
              <Route path="subscription" component={AdminBillingSettings} />
              <Route path="autopilot" component={AdminAutopilotBillingSettings} />
              <IndexRedirect to="/admin/settings/billing/subscription" />
            </Route>
          </Route>
        </Route>
        <Route path="changelog" component={AdminChangelogSettingsContainer}>
          <IndexRoute component={AdminChangelogSettingsRedirect} />
          <Route path="labels" component={AdminChangelogSettingsLabels} />
          <Route path="privacy" component={AdminChangelogSettingsPrivacy} />
        </Route>
        <Route path="fields" component={AdminFieldsSettingsContainer}>
          <IndexRoute component={AdminFieldsSettingsRedirect} />
          <Route component={CustomPostFieldsContainer}>
            <Route path="post-fields" component={AdminCustomPostFieldsSettings} />
          </Route>
          <Route path="company-fields" component={AdminCompanyFieldsSettings} />
        </Route>
        <Route path="custom-domain" component={AdminCNAMESettings} />
        <Route path="company" component={AdminGeneralSettingsContainer}>
          <IndexRoute component={AdminGeneralSettingsRedirect} />
          <Route path="branding" component={AdminGeneralBrandingSettings} />
          <Route path="preferences" component={AdminGeneralPreferencesSettings} />
          <Route path="delete" component={AdminGeneralSettingsDeletion} />
        </Route>
        <Route component={BoardsContainer}>
          <Route path="roadmap" component={AdminRoadmapSettingsContainer}>
            <IndexRoute component={AdminRoadmapStatusSettingsRedirect} />
            <Route path="archive" component={AdminRoadmapSettingsArchive} />
            <Route path="public-view" component={AdminRoadmapSettingsPublicView} />
            <Route path="statuses" component={AdminStatusSettings} />
          </Route>
        </Route>
        <Redirect from="email" to="preferences/email" />

        <Route component={ActiveIntegrationsContainer}>
          <Route path="integrations" component={AdminIntegrationsSettings} />
          <Route component={AsanaSettingsContainer}>
            <Route path="asana" component={AdminAsanaSettings} />
          </Route>
          <Route path="azure" component={AdminAzureSettings} />
          <Route component={AzureDevopsSettingsContainer}>
            <Route path="azure-devops" component={AzureDevopsSettings} />
          </Route>
          <Route path="clickup" component={AdminClickupSettings} />
          <Route component={BoardsContainer}>
            <Route component={DiscordSettingsContainer}>
              <Route path="discord" component={AdminDiscordSettings} />
            </Route>
          </Route>
          <Route path="github" component={AdminGitHubSettings} />
          <Route component={GongSettingsContainer}>
            <Route path="gong" component={AdminGongSettings} />
          </Route>
          <Route path="google-analytics" component={AdminGoogleAnalyticsSettings} />
          <Redirect from="gsuite" to="google-workspace" />
          <Route path="google-workspace" component={AdminGSuiteSettings} />
          <Route component={HelpscoutSettingsContainer}>
            <Route path="helpscout" component={AdminHelpscoutSettings} />
          </Route>
          <Route component={FreshdeskSettingsContainer}>
            <Route path="freshdesk" component={AdminFreshdeskSettings} />
          </Route>
          <Route path="hubspot" component={AdminHubspotSettings} />
          <Route path="intercom" component={AdminIntercomSettings} />
          <Route path="jira" component={AdminJiraSettings} />
          <Route component={LinearSettingsContainer}>
            <Route path="linear" component={AdminLinearSettings} />
          </Route>
          <Route component={BoardsContainer}>
            <Route path="microsoft-teams" component={AdminMicrosoftTeamsSettings} />
          </Route>
          <Route path="oidc" component={AdminOIDCSettings} />
          <Route path="okta" component={AdminOktaSettings} />
          <Route path="onelogin" component={AdminOneLoginSettings} />
          <Route path="salesforce" component={AdminSalesforceSettings} />
          <Route path="segment" component={AdminSegmentSettings} />
          <Route component={BoardsContainer}>
            <Route path="slack" component={AdminSlackSettings} />
          </Route>
          <Route path="zapier" component={AdminZapierSettings} />
          <Route path="zendesk" component={AdminZendeskSettings} />
          <Route component={ZoomSettingsContainer}>
            <Route path="zoom" component={AdminZoomSettings} />
          </Route>
        </Route>

        <Route component={BoardsContainer}>
          <Route path="autopilot" component={AdminAutopilotSettings}>
            <IndexRedirect to="feedback-discovery" />
            <Route path="feedback-discovery" component={AdminFeedbackDiscoverySettings} />
            <Route path="knowledge-hub" component={AdminKnowledgeHubSettings} />
          </Route>
        </Route>

        <Route path="preferences" component={AdminPreferencesSettings}>
          <IndexRedirect to="email" />
          <Route path="email" component={AdminEmailSettings} />
          <Route path="language" component={AdminLanguageSettings} />
        </Route>

        <Route component={ReferralSettingsContainer}>
          <Route path="referrals" component={AdminReferralSettings} />
        </Route>

        <Route path="saved-filters" component={AdminSavedFiltersSettings} />
        <Route path="sso-redirect" component={AdminAuthSettings} />
        <Route path="team" component={AdminMemberSettingsContainer}>
          <IndexRoute component={AdminTeamSettingsRedirect} />
          <Route path="people" component={AdminMemberPeopleSettings} />
          <Route path="roles" component={AdminMemberRoleSettings} />
        </Route>
        <Route path="user-emails" component={AdminNotificationsSettingsContainer}>
          <IndexRoute component={AdminNotificationSettingsRedirect} />
          <Route path="types" component={AdminUserEmailSettingsTypes} />
          <Route path="domain" component={AdminUserEmailSettingsDomain} />
        </Route>
      </Route>
    </Route>

    {/* public */}
    <Route component={PublicContainer}>
      <Route component={BoardsContainer}>
        <Route component={PublicRoadmapContainer}>
          <IndexRoute component={SubdomainHome} />
        </Route>
      </Route>

      <Route path="changelog" component={ChangelogContainer}>
        <Route component={ChangelogEntryListContainer}>
          <IndexRoute component={SubdomainChangelog} />
        </Route>

        <Route path=":entryURLName" component={ChangelogEntryContainer}>
          <IndexRoute component={SubdomainChangelogEntry} />
        </Route>
      </Route>

      <Route path="join" component={SubdomainJoinCompany} />

      <Route path="notifications" component={CompanyNotificationsContainer}>
        <IndexRoute component={SubdomainNotifications} />
      </Route>

      <Route component={PostListContainer}>
        <Route path="search" component={SubdomainSearch} />
      </Route>

      <Route path="settings" component={SubdomainAccountSettings} />

      <Route path="unsubscribe" component={SubdomainUnsubscribe} />
      <Route path="unsubscribe-report" component={SubdomainUnsubscribeReport} />

      <Route path=":boardURLName" component={BoardContainer}>
        <Route component={BoardsContainer}>
          <Route component={PostListContainer}>
            <IndexRoute component={BoardHome} />
          </Route>
        </Route>
        <Route component={PostListContainer}>
          <Route path="create" component={SubdomainCreatePost} />
        </Route>
        <Route path="p/:postURLName" component={PostContainer}>
          <Route component={PostActivityContainer}>
            <IndexRoute component={SubdomainPost} />
          </Route>
          <Route path="voters" component={PostVoterContainer}>
            <IndexRoute component={SubdomainPostVotersList} />
          </Route>
        </Route>
      </Route>

      <Route path="roadmap/:roadmapURLName/:roadmapViewID" component={SharedRoadmapContainer}>
        <Route component={CustomPostFieldsContainer}>
          <Route component={SharedRoadmapPostsContainer}>
            <Route component={BoardsContainer}>
              <IndexRoute component={SharedRoadmap} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>

    {/* Redirect old docs links to developers.canny.io */}
    <ExternalRedirect from="/admin/docs" to={devURL('https://developers.canny.io')} />
    <ExternalRedirect
      from="/admin/docs/companies"
      to={devURL('https://developers.canny.io/install/companies')}
    />
    <ExternalRedirect
      from="/admin/docs/install"
      to={devURL('https://developers.canny.io/install')}
    />
    <ExternalRedirect
      from="/admin/docs/widget"
      to={devURL('https://developers.canny.io/install/widget')}
    />
    <ExternalRedirect
      from="/admin/docs/widget/mobile"
      to={devURL('https://developers.canny.io/install/widget/mobile')}
    />
    <ExternalRedirect
      from="/admin/docs/widget/sso"
      to={devURL('https://developers.canny.io/install/widget/sso')}
    />
    <ExternalRedirect
      from="/admin/docs/widget/web"
      to={devURL('https://developers.canny.io/install/widget/web')}
    />
    <ExternalRedirect from="/admin/install" to={devURL('https://developers.canny.io/install')} />
    <ExternalRedirect
      from="/admin/widget"
      to={devURL('https://developers.canny.io/install/widget')}
    />
    <ExternalRedirect
      from="/admin/widget/mobile"
      to={devURL('https://developers.canny.io/install/widget/mobile')}
    />
    <ExternalRedirect
      from="/admin/widget/sso"
      to={devURL('https://developers.canny.io/install/widget/sso')}
    />
    <ExternalRedirect
      from="/admin/widget/web"
      to={devURL('https://developers.canny.io/install/widget/web')}
    />

    {/* 404 */}
    <Route path="*" component={SubdomainFourOhFour} />
  </Route>
);
