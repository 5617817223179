import React, { useCallback, useContext, useEffect, useState } from 'react';

import classnames from 'classnames';
import { Check } from 'lucide-react';
import { type Dispatch } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import { useGettingStartedContext } from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotGettingStarted';
import {
  type Integration,
  IntegrationMap,
  Integrations,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import { installAndEnable } from 'common/subdomain/admin/AdminQueue/GettingStarted/utils';
import ButtonV2 from 'common/ui/ButtonV2';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotGettingStarted.module.scss';

import AdminAutopilotGettingStartedModalSwitch from './modals/AdminAutopilotGettingStartedModalSwitch';

type ConnectProps = {
  reloadCompany: () => void;
};

type OwnProps = {
  enabledSyncIntegrations: SyncIntegrationNames[];
};

type Props = ConnectProps & OwnProps;

const AdminAutopilotGettingStartedIntegrations = ({
  enabledSyncIntegrations,
  reloadCompany,
}: Props) => {
  // context
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  // state
  const [currentIntegration, setCurrentIntegration] = useState<null | Integration>(null);
  const { setLoading, setErroredIntegrationName } = useGettingStartedContext();

  // helpers
  const openIntegrationSetup = (integration: Integration) => {
    setCurrentIntegration(integration);
  };

  const closeIntegrationSetup = () => {
    setCurrentIntegration(null);
  };

  // called from modal if integration is already installed
  const enableIntegrationSync = useCallback(
    async (integration: Integration) => {
      setLoading(true);
      setErroredIntegrationName();

      const { name } = integration;

      const response = await AJAX.post(`/api/queue/toggleIntegrationSync`, {
        integrationName: name,
        enabled: true,
      });

      setLoading(false);

      const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
      if (error) {
        setErroredIntegrationName(integration.label);
      }

      reloadCompany();
    },
    [reloadCompany, setLoading, setErroredIntegrationName]
  );

  const handleIntegrationCode = useCallback(
    async (code: string, integrationName: SyncIntegrationNames, subdomain: string) => {
      setLoading(true);
      setErroredIntegrationName();
      const integrationLabel = IntegrationMap[integrationName].label;
      try {
        await installAndEnable({
          integrationName,
          integrationLabel,
          conversationSource: { code, subdomain },
        });
      } catch (error) {
        setErroredIntegrationName(integrationLabel);
      } finally {
        await reloadCompany();
        setLoading(false);
      }
    },
    [setErroredIntegrationName, setLoading, reloadCompany]
  );

  useEffect(() => {
    const {
      code,
      type,
      subdomain,
    }: { code: string; type: SyncIntegrationNames; subdomain: string } = location.query;
    if (!code || !type) {
      return;
    }

    if (type === SyncIntegrationNames.zendesk && !subdomain) {
      setErroredIntegrationName(SyncIntegrationNames.zendesk);
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    handleIntegrationCode(code, type, subdomain);
  }, [location, router, handleIntegrationCode, setErroredIntegrationName]);

  // render
  return (
    <>
      <ul className={styles.integrationList}>
        {Integrations.map((integration) => {
          const enabledAsSource = enabledSyncIntegrations.includes(integration.name);
          return (
            <li
              className={classnames([
                styles.integrationItem,
                { [styles.enabledIntegration]: enabledAsSource },
              ])}
              key={`${integration.name}_integration`}>
              <ButtonV2
                variant="outlined"
                size="medium"
                className={styles.integrationButton}
                onClick={() => openIntegrationSetup(integration)}
                disabled={enabledAsSource}
                aria-label={
                  enabledAsSource
                    ? `${integration.label} has been installed.`
                    : `Open a modal to start the flow of installing ${integration.label}.`
                }>
                <span className={styles.buttonLabel}>
                  {!enabledAsSource ? (
                    <LazyLoadedImage src={integration.icon} className={styles.logo} alt="" />
                  ) : (
                    <Check strokeWidth={4} size={12} className={styles.logo} />
                  )}
                  {integration.label}
                </span>
              </ButtonV2>
            </li>
          );
        })}
      </ul>
      <AdminAutopilotGettingStartedModalSwitch
        onClose={closeIntegrationSetup}
        integration={currentIntegration}
        enableIntegrationSync={enableIntegrationSync}
      />
    </>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    return dispatch(reloadCompany());
  },
}))(AdminAutopilotGettingStartedIntegrations) as unknown as React.FC<OwnProps>;
