import React from 'react';

import classnames from 'classnames';
import { AlertCircle, StickyNote } from 'lucide-react';

import { P } from 'common/ui/Text';
import styles from 'css-module/components/common/_Alert.module.scss';

export enum AlertTypes {
  Danger = 'Danger',
  Info = 'Info',
}

const AlertModes: Record<AlertTypes, { icon: React.ElementType; className: string }> = {
  [AlertTypes.Danger]: {
    icon: AlertCircle,
    className: styles.danger,
  },
  [AlertTypes.Info]: {
    icon: StickyNote,
    className: styles.info,
  },
} as const;

type Props = {
  headingText: string;
  subText: React.ReactNode;
  button?: React.ReactNode;
  type: AlertTypes;
  className?: string;
};

const Alert = ({ headingText, subText, button, type, className }: Props) => {
  const { icon: Icon, className: modeClassname } = AlertModes[type];

  return (
    <div className={classnames(className, styles.alert, modeClassname)}>
      <Icon className={styles.icon} size={14} />
      <P className={styles.heading} variant="bodyMd" fontWeight="semibold">
        {headingText}
      </P>
      <P className={styles.subText}>{subText}</P>
      <div className={styles.buttonContainer}>{button}</div>
    </div>
  );
};

export default Alert;
