import React, { useContext, useRef, useState } from 'react';

import { FileSearch2, MessageSquare, TextSelect } from 'lucide-react';
import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Colors from 'common/colors/constants';
import Card from 'common/common/Card';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import withAccessControl from 'common/routing/withAccessControl';
import AdminCCModal from 'common/subdomain/admin/billing/AdminCCModal';
import Tappable from 'common/Tappable';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import SwitchV2 from 'common/ui/SwitchV2';
import { H4, H5, H6, P } from 'common/ui/Text';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/billing/_AdminAutopilotBillingSettings.scss';

const MaxCreditLimit = 5000;
const PillStyle = {
  color: Colors.gray120,
  background: DefaultPillStyles.info.background,
};

type ConnectProps = {
  reloadCompany: () => Promise<void>;
};

type OwnProps = Record<string, never>;
type Props = OwnProps & ConnectProps;

const AdminAutopilotBillingSettings = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showIntercom = useContext(ShowIntercomContext);

  const creditLimitRef = useRef<TextInput>(null);
  const typeTimeout = useRef<NodeJS.Timeout>();

  const { additionalCreditsDisabled, creditLimit, planLimit, usedCredits } = company.autopilot;
  const [additionalCreditLimit, setACL] = useState(company.autopilot.additionalCreditLimit);
  const [unlimitedAdditionalCredits, setUAC] = useState(
    company.autopilot.unlimitedAdditionalCredits
  );

  const [ccModal, setShowCCModal] = useState(false);
  const [calculatingPrice, setCalculating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  function onCloseCCModal() {
    setShowCCModal(false);
    setError('Must provide CC in order to purchase additional credits');
    setSaving(false);
  }

  function onSuccessCCModal() {
    setShowCCModal(false);
    saveAutopilotBilling();
  }

  const saveAutopilotBilling = async () => {
    setSaving(true);
    setError('');

    const response = await AJAX.post('/api/billing/updateAutopilot', {
      additionalCreditLimit,
      unlimitedAdditionalCredits,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error?.type === 'no card') {
      setShowCCModal(true);
      return;
    }

    if (error) {
      setError(error.message);
    } else {
      await reloadCompany();
    }

    setSaving(false);
  };

  function roundCredits(newACL: number) {
    setACL(newACL);
    setCalculating(false);

    if (!creditLimitRef.current) {
      return;
    }

    creditLimitRef.current.setValue(newACL.toString());
  }

  const updateCredits = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeTimeout.current) {
      clearTimeout(typeTimeout.current);
    }

    const value = Number(event.target.value);
    if (value < MaxCreditLimit && value % 100 === 0) {
      setACL(value);
      setCalculating(false);
      return;
    }

    setCalculating(true);
    const newACL = Math.min(Math.ceil(value / 100) * 100, MaxCreditLimit);
    typeTimeout.current = setTimeout(() => {
      roundCredits(newACL);
    }, 2000);
  };

  const price = (additionalCreditLimit / 10).toFixed(2);
  const valueChanged =
    company.autopilot.additionalCreditLimit !== additionalCreditLimit ||
    company.autopilot.unlimitedAdditionalCredits !== unlimitedAdditionalCredits;

  let saveButtonLabel = 'Save';
  let loading = false;
  if (saving) {
    saveButtonLabel = 'Saving';
    loading = true;
  } else if (valueChanged && calculatingPrice) {
    saveButtonLabel = 'Calculating';
    loading = true;
  }

  return (
    <div className="adminAutopilotBillingSettings">
      <Helmet title={`Autopilot Usage | Canny`} />
      <H5 className="header" fontWeight="bold">
        Details
      </H5>
      {additionalCreditsDisabled && (
        <Alert
          type={AlertTypes.Danger}
          headingText="Credit Purchasing Disabled"
          subText="There was an issue purchasing credits with your billing method. Please contact support to enable."
          button={
            <Button
              buttonType="ghostButton"
              className="destructive"
              value="Contact Support"
              onTap={showIntercom}
            />
          }
        />
      )}
      <Card borderStyle="solid" className="creditsUsedCard">
        <P className="subheader" fontWeight="medium">
          Credits Used
        </P>
        <div className="usage">
          <div className="credits">
            <H4 className="used" fontWeight="bold">
              {usedCredits}
            </H4>
            <H6 className="limit" fontWeight="bold">
              of {creditLimit}
            </H6>
          </div>
          <Pill className="pill" pillStyle={PillStyle}>
            {planLimit} free credits
          </Pill>
        </div>
      </Card>
      <H5 className="header" fontWeight="bold">
        Usage Limit
      </H5>
      <div className="toggleContainer">
        <div>
          <P className="subheader" fontWeight="medium">
            Set a usage limit for this workspace
          </P>
          <P className="description">
            When usage hits the limit you've set, Canny Autopilot will stop working. We'll let your
            billing contacts know and apply the conditions listed below. You will be billed
            incrementally every 100 credits.
          </P>
        </div>
        <SwitchV2
          checked={!unlimitedAdditionalCredits}
          onChange={(value) => setUAC(!value)}
          size="medium"
        />
      </div>
      {!unlimitedAdditionalCredits && (
        <>
          <div className="usageTitle">
            <P className="subheader" fontWeight="medium">
              Credit Limit
            </P>
            <Pill>10¢ / credit</Pill>
          </div>
          <div className="additionalCreditLimit">
            <TextInput
              className="additionalCreditLimitInput"
              defaultValue={additionalCreditLimit.toString()}
              min="0"
              name="additionalCreditLimit"
              ref={creditLimitRef}
              step="100"
              suffix={<P className="suffix">credits</P>}
              onChange={updateCredits}
              type="number"
            />
            <P className="price">${price}</P>
          </div>
        </>
      )}
      {(unlimitedAdditionalCredits || additionalCreditLimit === MaxCreditLimit) && (
        <P className="subheader" fontWeight="medium">
          Canny allows your organization {numberWithCommas(MaxCreditLimit)} additional credits each
          month, and is subject to change. If you would like to increase your limit beyond this
          amount,{' '}
          <Tappable onClick={showIntercom}>
            <span className="contactSupport">please contact support</span>
          </Tappable>
          .
        </P>
      )}

      <Card borderStyle="solid" className="featuresCard">
        <P className="subheader" fontWeight="medium">
          Which features require Autopilot credits?
        </P>
        <div className="features">
          <div className="feature">
            <div className="icon">
              <FileSearch2 size={20} />
            </div>
            <div className="content">
              <div className="featureTitle">
                <P fontWeight="semibold">Feedback Discovery</P>
                <Pill>Custom credits / source</Pill>
              </div>
              <P className="featureBody">
                Extract and deduplicate feedback from customer communication sources
              </P>
            </div>
          </div>
          <div className="feature">
            <div className="icon">
              <MessageSquare size={20} />
            </div>
            <div className="content">
              <div className="featureTitle">
                <P fontWeight="semibold">Smart replies</P>
                <Pill>1 credit / reply</Pill>
              </div>
              <P className="featureBody">
                Automate thoughtful, human-like responses for every post to get additional context
              </P>
            </div>
          </div>
          <div className="feature">
            <div className="icon">
              <TextSelect size={20} />
            </div>
            <div className="content">
              <div className="featureTitle">
                <P fontWeight="semibold">Comment Summarization</P>
                <Pill>1 credit / summary</Pill>
              </div>
              <P className="featureBody">
                Recap lengthy feedback threads to understand what your customers want
              </P>
            </div>
          </div>
        </div>
      </Card>

      <div className="footer">
        <ButtonV2
          className="saveButton"
          disabled={!valueChanged || calculatingPrice || saving}
          onClick={saveAutopilotBilling}
          loading={loading}
          size="medium">
          {saveButtonLabel}
        </ButtonV2>
      </div>
      {error && <div className="error">{error}</div>}
      {ccModal && <AdminCCModal onClose={onCloseCCModal} onSuccess={onSuccessCCModal} />}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.billing.autopilot),
    '/admin/settings'
  )
)(AdminAutopilotBillingSettings) as unknown as React.FC<OwnProps>;
